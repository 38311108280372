import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    ns: ["common", "components", "experiences"],
    defaultNS: "common",
    debug: true,
    fallbackLng: "fr",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        common: {
          start: "Start",
        },
        components: {
          welcome: {
            title: "Bienvenue",
            subtitle: "dans le démonstrateur immersif du River-cooling",
            firstParagraph:
              "Découvrez le dispositif innovant du River Cooling réalisé dans le cadre du Smart Port Challenge en partenariat avec Interxion, My Digital Buildings et Dalkia Smart Building/EDF.",
            secondParagraph:
              "Ce disposif innovant permet de refroidir les data-centers en générant d’importantes économies d’énergie.",
          },
        },
      },
      fr: {
        common: {
          start: "Démarrer",
        },
        components: {
          welcome: {
            title: "Bienvenue",
            subtitle: "dans le démonstrateur immersif du River-cooling",
            firstParagraph:
              "Découvrez le dispositif innovant du River Cooling réalisé dans le cadre du Smart Port Challenge en partenariat avec Interxion, My Digital Buildings et Dalkia Smart Building/EDF.",
            secondParagraph:
              "Ce disposif innovant permet de refroidir les data-centers en générant d’importantes économies d’énergie.",
          },
        },
      },
    },
  });

export default i18n;
