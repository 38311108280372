import React from "react";
import IconPoi from "assets/svg/IconPoi";
import styles from "./Edge.module.css";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";

const Edge = () => {
  const { position, recenter } = useSelector((state) => state.appReducer.edge);
  if (!position) return null;
  return (
    <div
      style={{ ...position.style }}
      onClick={() => {
        recenter();
      }}
      className={`${styles.edgeContainer} ${styles[position.className]}`}
    >
      <motion.div
        animate={{
          scale: [1, 1.05, 0.95, 1.05, 1, 1, 1],
          rotate: [45, 45, 45, 45, 45, 45, 45],
          x: [0, 0, -8, 0, -4, 0, 0],
        }}
        transition={{
          duration: 1.2,
          loop: Infinity,
        }}
        className={styles.edge}
      >
        <div className={styles.edgeContent}>
          <IconPoi />
        </div>
      </motion.div>
    </div>
  );
};

export default Edge;
