import React from "react";

function IconArrowDown() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M3.515 9.9a1 1 0 111.414-1.415L12 15.556l7.071-7.071a1 1 0 011.414 1.414l-7.778 7.778a1 1 0 01-1.327.079l-.087-.079L3.515 9.9z"
      ></path>
    </svg>
  );
}

export default IconArrowDown;
