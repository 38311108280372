import React from "react";

function IconSoundOn() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M19.102 2.701c2.18 2.562 3.398 5.809 3.398 9.243s-1.218 6.68-3.398 9.242a.5.5 0 11-.761-.648 13.214 13.214 0 003.159-8.594 13.21 13.21 0 00-3.16-8.595.5.5 0 01.762-.648zm-8.038 2.646a.5.5 0 01.116.32V18.22a.5.5 0 01-.82.384l-4.688-3.906H2.5a.5.5 0 01-.5-.5V9.69a.5.5 0 01.5-.5h3.172l4.688-3.907a.5.5 0 01.704.064zm4.104.922a8.729 8.729 0 012.086 5.674 8.728 8.728 0 01-2.086 5.675.5.5 0 01-.762-.648 7.725 7.725 0 001.848-5.027 7.726 7.726 0 00-1.848-5.026.5.5 0 01.762-.648z"
      ></path>
    </svg>
  );
}

export default IconSoundOn;
