import { ButtonOutline } from "components/button";
import FingerPrint from "components/hotspots/FingerPrint";
import Modal from "components/modal";
import React from "react";
import styles from "./Unlock.module.scss";
import button from "components/button/Button.module.scss";
import fingerReaderLock from "assets/img/finger-reader-lock.jpg";
import fingerReaderUnlock from "assets/img/finger-reader-unlock.jpg";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import IconClose from "assets/svg/IconClose";

const ModalUnlock = () => {
  const [unlock, setUnlock] = React.useState(false);
  const [currentText, setCurrentText] = React.useState(
    "Cliquer sur l'empreinte"
  );
  const [finish, setFinish] = React.useState(false);
  const timeoutRef = React.useRef();
  const dispatch = useDispatch();
  const currentGoal = useSelector((state) => state.appReducer.currentGoal);

  React.useEffect(() => {
    if (!unlock) return;
    setCurrentText("Scan en cours…");
    timeoutRef.current = window.setTimeout(() => {
      setFinish(true);
    }, 2000);
  }, [unlock]);

  function handleUnlockGoal() {
    const unlockTarget = currentGoal.unlockTarget;
    dispatch({
      type: "UNLOCK_GOAL",
    });
    dispatch({
      type: "GO_TO_SCENE_BY_ID",
      sceneId: unlockTarget,
    });
  }

  return (
    <Modal>
      <div
        className={styles.scanId}
        style={{
          backgroundImage: `url(${
            finish ? fingerReaderUnlock : fingerReaderLock
          })`,
        }}
      >
        {/* Preload second image */}
        <img style={{ display: "none" }} src={fingerReaderLock} alt="" />
        <FingerPrint active={unlock} handleSetActive={() => setUnlock(true)} />
      </div>
      {finish ? (
        <div className="text-center">
          <ButtonOutline onClick={handleUnlockGoal}>Continuer</ButtonOutline>
        </div>
      ) : (
        <div className={styles.text}>{currentText}</div>
      )}
    </Modal>
  );
};

export default ModalUnlock;
