import React from "react";
import styles from "./Drawer.module.scss";
import { motion } from "framer-motion";
import { DrawerNavbar } from "components/navbar";

const variants = {
  open: {
    opacity: 1,
    x: 0,
    transition: {
      type: "spring",
      stiffness: 30,
      restDelta: 1.5,
    },
  },
  closed: {
    opacity: 0,
    x: "100%",
    transition: {
      delay: 0.2,
      type: "spring",
      stiffness: 200,
      damping: 40,
    },
  },
};

const Drawer = ({ children, isOpen, name, onLeftClick }) => {
  return (
    <motion.div
      initial={false}
      animate={isOpen ? "open" : "closed"}
      variants={variants}
      className={styles.drawer}
    >
      <DrawerNavbar name={name} onLeftClick={onLeftClick} />
      {children}
    </motion.div>
  );
};

export default Drawer;
