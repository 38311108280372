import IconFullscreen from "assets/svg/IconFullScreen";
import IconPlay from "assets/svg/IconPlay";
import React from "react";
import styles from "./ColdCorridor.module.scss";

const ColdCorridorContent = () => {
  return (
    <div className={styles.contentView}>
      <h3>
        <strong>Air froid</strong> soufflé
      </h3>
      <p>
        L’air froid est maintenant soufflé en façade des serveurs pour les
        refroidir. Des serveurs en pleine activité peuvent fonctionner dans des
        environnements allant jusqu’à 27°C en toute sécurité, suivant les
        recommandations et conditions définies par l'ASHRAE (Association
        professionnelle de référence).
      </p>
      <VideoBloc
        name="L'air froid soufflé du plancher"
        thumb="/card_thumbs/Card_Grids_floor.jpg"
        src="/video/AirFlowGrid.mp4"
      />
      <VideoBloc
        name="Les serveurs refroidis"
        thumb="/card_thumbs/Card_Server_video.jpg"
        src="/video/ServersCooling.mp4"
      />
    </div>
  );
};

const VideoBloc = ({ name, thumb, src }) => {
  const [play, setPlay] = React.useState(false);
  const [fullScreen, setFullScreen] = React.useState(false);
  const videoEl = React.useRef();

  function handleExitIos() {
    setFullScreen(false);
  }

  React.useEffect(() => {
    if (!fullScreen) return false;
    if (videoEl.current.requestFullscreen) {
      videoEl.current.requestFullscreen();
    } else if (videoEl.current.webkitEnterFullScreen) {
      /* Safari */
      videoEl.current.webkitEnterFullScreen();
      videoEl.current.addEventListener("webkitendfullscreen", handleExitIos);
      return () => {
        videoEl.current.removeEventListener(
          "webkitendfullscreen",
          handleExitIos
        );
      };
    }
  }, [fullScreen]);

  return (
    <div className={styles.videoBloc} onClick={() => setPlay(true)}>
      <img src={thumb} alt={`miniature video : ${name}`} />
      <h3>{name}</h3>
      <span>Vidéo</span>
      {play ? (
        <>
          <button
            className={styles.fullscreen}
            onClick={() => {
              setFullScreen(true);
            }}
          >
            <IconFullscreen />
          </button>
          <video
            ref={videoEl}
            playsInline={!fullScreen}
            autoPlay
            controls={false}
            className={styles.videoPlayer}
          >
            <source src={src} type="video/mp4" />
          </video>
        </>
      ) : (
        <div className={styles.playBtn}>
          <IconPlay />
        </div>
      )}
    </div>
  );
};

export default ColdCorridorContent;
