import React from "react";

function IconCloseThin() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill="currentColor"
          d="M19.071 4.929a1 1 0 010 1.414L13.414 12l5.657 5.657a1 1 0 01-1.414 1.414L12 13.414l-5.657 5.657a1 1 0 01-1.414-1.414L10.586 12 4.929 6.343A1 1 0 016.343 4.93L12 10.586l5.657-5.657a1 1 0 011.414 0z"
        ></path>
      </g>
    </svg>
  );
}

export default IconCloseThin;
