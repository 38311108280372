import Icon360 from "assets/svg/Icon360";
import IconCheck from "assets/svg/IconCheck";
import { ButtonOutline } from "components/button";
import { ButtonPrimary } from "components/button";
import React from "react";
import styles from "./Splash.module.scss";
import { HotspotInfo } from "components/hotspots";
import { motion, AnimatePresence } from "framer-motion";
import button from "components/button/Button.module.scss";
import IconClose from "assets/svg/IconClose";

const initialState = {
  currentStep: 0,
};

const CHANGE_STEP = "CHANGE_STEP";
const NEXT_STEP = "NEXT_STEP";
const PREV_STEP = "PREV_STEP";

function onBoardReducer(state, action) {
  switch (action.type) {
    case CHANGE_STEP:
      return {
        ...state,
        currentStep: action.payload,
      };
    case NEXT_STEP:
      return {
        ...state,
        currentStep: state.currentStep + 1,
      };
    case PREV_STEP:
      return {
        ...state,
        currentStep: state.currentStep - 1,
      };
    default:
      return state;
  }
}

const BigCheck = () => {
  return (
    <div className={styles.bigCheck}>
      <IconCheck />
    </div>
  );
};

const steps = [
  {
    icon: <Icon360 />,
    title: "",
    text: "Balayez l'écran pour découvrir l'intégralité des panoramiques",
  },
  {
    icon: (
      <HotspotInfo
        style={{ position: "static", margin: "2rem 0" }}
        hotspot={{ icon: "up" }}
      />
    ),
    title: "",
    text:
      "Suivez et touchez les points interactif pour atteindre votre objectif",
  },
  {
    icon: <BigCheck />,
    title: "Vous êtes prêt !",
    text: "Bonne expérience",
  },
];

const Onboard = ({ handleClose }) => {
  return (
    <div className={styles.center}>
      <h2 className={styles.onboardTitle}>Avant de commencer</h2>
      <div className={styles.text}>
        <p>
          Faisons un petit tour d’horizon des principales interactions pour
          naviguer dans l’expérience 360°
        </p>
      </div>
      <Slide handleClose={handleClose} />
    </div>
  );
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity;
};

const Slide = ({ handleClose }) => {
  const [state, dispatch] = React.useReducer(onBoardReducer, initialState);

  const { currentStep } = state;
  const step = steps[currentStep];
  const variants = {
    inactive: {
      opacity: 0.5,
      scaleX: 0.666666667,
      borderRadius: "6px",
    },
    active: {
      opacity: 1,
      scaleX: 1,
      borderRadius: "4px",
    },
  };

  function handleContinue() {
    if (currentStep + 1 === steps.length) {
      handleClose();
    } else {
      dispatch({ type: NEXT_STEP });
    }
  }
  function handlePrev() {
    if (currentStep === 0) {
      return false;
    } else {
      dispatch({ type: PREV_STEP });
    }
  }

  return (
    <>
      <div className={styles.card}>
        <AnimatePresence exitBeforeEnter>
          <motion.div
            // initial={{ opacity: 0, x: 10 }}
            // animate={{ opacity: 1, x: 0 }}
            // exit={{ opacity: 0, x: -10 }}
            className={styles.cardContent}
            drag="x"
            dragConstraints={{ left: 0, right: 0 }}
            onDragEnd={(e, { offset, velocity }) => {
              const swipe = swipePower(offset.x, velocity.x);
              if (swipe < -swipeConfidenceThreshold) {
                handleContinue();
              } else if (swipe > swipeConfidenceThreshold) {
                handlePrev();
              }
            }}
          >
            {step.icon && step.icon}
            {step.title && <h3 className={styles.cardTitle}>{step.title}</h3>}
            <p className={styles.cardText}>{step.text}</p>
            <ButtonPrimary
              onClick={() => {
                handleContinue();
              }}
            >
              Continuer
            </ButtonPrimary>
          </motion.div>
        </AnimatePresence>
        <div className={styles.cardSteps}>
          {steps.map((s, i) => {
            return (
              <motion.div
                initial={false}
                animate={i === currentStep ? "active" : "inactive"}
                variants={variants}
                key={`dot-${i}`}
                className={`${styles.dot} ${
                  i === currentStep ? styles.dotActive : ""
                }`}
              ></motion.div>
            );
          })}
        </div>
      </div>
      <div className={styles.bottomAction}>
        {currentStep + 1 !== steps.length ? (
          <ButtonOutline onClick={handleClose}>
            Passer le tutoriel
          </ButtonOutline>
        ) : (
          <button className={button.modalButton} onClick={handleClose}>
            <IconClose />
          </button>
        )}
      </div>
    </>
  );
};

export default Onboard;
