import { ButtonPrimary } from "components/button";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "./Closing.module.scss";

const ClosingCard = () => {
  const dispatch = useDispatch();
  const currentScene = useSelector((state) => state.appReducer.currentScene);

  function handleGoNext() {
    dispatch({
      type: "GO_TO_SCENE_BY_ID",
      sceneId: "CLOSING",
    });
  }

  React.useEffect(() => {
    if (currentScene?.guide_card?.target === "CLOSING") {
      document.getElementById("root").classList.add("isLastScene");
    }
    return () => {
      document.getElementById("root").classList.remove("isLastScene");
    };
  }, [currentScene]);

  if (currentScene?.guide_card?.target !== "CLOSING") return null;

  return (
    <div className={styles.cardBottom}>
      <ButtonPrimary full onClick={handleGoNext}>
        Voir la synthèse du River Cooling
      </ButtonPrimary>
    </div>
  );
};

export default ClosingCard;
