import React from "react";
import styles from "../coldCorridor/ColdCorridor.module.scss";
import button from "components/button/Button.module.scss";
import Drawer from "components/drawer";
import { useSelector, useDispatch } from "react-redux";
import IconClose from "assets/svg/IconClose";

const ColdUnit = () => {
  const currentGoal = useSelector((state) => state.appReducer.currentGoal);
  const dispatch = useDispatch();

  function handleClose() {
    dispatch({
      type: "UNLOCK_GOAL",
    });
    dispatch({
      type: "GO_TO_SCENE_BY_ID",
      sceneId: currentGoal.unlockTarget,
    });
  }

  return (
    <Drawer isOpen={true} onLeftClick={handleClose} name="Les groupes froid">
      <div className={styles.viewerFull}>
        <div className={styles.viewerImage}>
          <img
            className={styles.tabImage}
            src="/images/groupe_froid.jpg"
          />
        </div>
      </div>
      <div className={styles.tabContent}>
        Un groupe froid est un dispositif qui refroidit un liquide caloporteur
        pour des applications industrielles. Le coefficient de performance d'un
        groupe froid est généralement entre 2 et 4. Pour 100 kWh d'électricité
        consommés, un groupe froid produit de 200 à 400 kWh thermiques.
      </div>
      <div className={styles.bottom}>
        <button className={button.modalButton} onClick={handleClose}>
          <IconClose />
        </button>
      </div>
    </Drawer>
  );
};

export default ColdUnit;
