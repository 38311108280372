import { ButtonPrimary } from "components/button";
import React from "react";
import styles from "./ColdCorridor.module.scss";

const NextTab = ({ handleNext, isLast }) => {
  return (
    <div className={styles.cardBottom}>
      <ButtonPrimary full onClick={handleNext}>
        {isLast ? "Terminer" : "Continuer"}
      </ButtonPrimary>
    </div>
  );
};

export default NextTab;
