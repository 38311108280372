import React from "react";
import ReactDOM from "react-dom";
import Marzipano from "marzipano";
import { HotspotInfo, HotspotLink, Tooltip } from "../../components/hotspots";

const hotSpotW = 40;

const POSITION = {
  LEFT: "pos-l",
  RIGHT: "pos-r",
  TOP: "pos-t",
  BOTTOM: "pos-b",
  TOP_RIGHT: "pos-tr",
  TOP_LEFT: "pos-tl",
  BOTTOM_LEFT: "pos-bl",
  BOTTOM_RIGHT: "pos-br",
};

export function hotspotInViewport({ x, y }) {
  const { innerWidth, innerHeight } = window;
  const outLeft = x < -hotSpotW;
  const outRight = x > innerWidth + hotSpotW;
  const outTop = y < -hotSpotW;
  const outBottom = y > innerHeight + hotSpotW;
  if (!outLeft && !outRight && !outBottom && !outTop) return false;
  if (outLeft && outBottom)
    return {
      style: { left: "0px", bottom: "0px", top: "auto", right: "auto" },
      className: POSITION.BOTTOM_LEFT,
    };
  if (outLeft && outTop)
    return {
      style: { left: "0px", bottom: "auto", top: "0px", right: "auto" },
      className: POSITION.TOP_LEFT,
    };
  if (outRight && outBottom)
    return {
      style: { left: "auto", bottom: "0px", top: "auto", right: "0px" },
      className: POSITION.BOTTOM_RIGHT,
    };
  if (outRight && outTop)
    return {
      style: { left: "auto", bottom: "auto", top: "0px", right: "0px" },
      className: POSITION.TOP_RIGHT,
    };
  if (outLeft)
    return {
      style: { left: "0px", bottom: "auto", top: y, right: "auto" },
      className: POSITION.LEFT,
    };
  if (outRight)
    return {
      style: { left: "auto", bottom: "auto", top: y, right: "0px" },
      className: POSITION.RIGHT,
    };
  if (outBottom)
    return {
      style: { left: x, bottom: "0px", top: "auto", right: "auto" },
      className: POSITION.BOTTOM,
    };
  if (outTop)
    return {
      style: { left: x, bottom: "auto", top: "0px", right: "auto" },
      className: POSITION.TOP,
    };
}

export function detectPlatform() {
  // Detect desktop or mobile mode.
  if (window.matchMedia) {
    var setMode = function () {
      if (mql.matches) {
        document.body.classList.remove("desktop");
        document.body.classList.add("mobile");
      } else {
        document.body.classList.remove("mobile");
        document.body.classList.add("desktop");
      }
    };
    var mql = matchMedia("(max-width: 500px), (max-height: 500px)");
    setMode();
    mql.addListener(setMode);
  } else {
    document.body.classList.add("desktop");
  }
}

export function detectTouchDevice() {
  // Detect whether we are on a touch device.
  document.body.classList.add("no-touch");
  window.addEventListener("touchstart", function () {
    document.body.classList.remove("no-touch");
    document.body.classList.add("touch");
  });
}

const getCompoBaseOnType = (type) => {
  if (type === "link") {
    return HotspotLink;
  }
  if (type === "info") {
    return HotspotInfo;
  }
};

export const createHotspot = ({
  hotspotData,
  sceneViewer,
  type,
  props = {},
}) => {
  if (!hotspotData) {
    return null;
  }
  const el = document.createElement("div");
  const HostpotComp = getCompoBaseOnType(type);
  ReactDOM.render(<HostpotComp hotspot={hotspotData} {...props} />, el);
  return sceneViewer
    .hotspotContainer()
    .createHotspot(el, { yaw: hotspotData.yaw, pitch: hotspotData.pitch });
};

export const createToolTip = ({ tooltip, sceneViewer }) => {
  if (!tooltip) {
    return null;
  }
  const el = document.createElement("div");
  ReactDOM.render(<Tooltip title={tooltip.title} />, el);
  return sceneViewer
    .hotspotContainer()
    .createHotspot(el, { yaw: tooltip.yaw, pitch: tooltip.pitch });
};

export const createSceneViewer = ({ scene, viewer }) => {
  const source = Marzipano.ImageUrlSource.fromString(
    `/tiles/${scene.id}/{z}/{f}/{y}/{x}.jpg`,
    { cubeMapPreviewUrl: `/tiles/${scene.id}/preview.jpg` }
  );
  const geometry = new Marzipano.CubeGeometry(scene.levels);
  const limiter = Marzipano.RectilinearView.limit.traditional(
    scene.faceSize,
    (100 * Math.PI) / 180,
    (120 * Math.PI) / 180
  );
  const view = new Marzipano.RectilinearView(
    scene.initialViewParameters,
    limiter
  );
  return viewer.createScene({
    source: source,
    geometry: geometry,
    view: view,
    pinFirstLevel: true,
  });
};
