import React from "react";
import styles from "experiences/coldCorridor/ColdCorridor.module.scss";
import { Tab, TabPanel } from "@reach/tabs";
import TabsContainer from "components/tabs";
import Drawer from "components/drawer";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import "experiences/coldCorridor/main.scss";
import { useSelector, useDispatch } from "react-redux";
import ViewerTutorial from "experiences/coldCorridor/ViewerTutorial";
import { ButtonPrimary, ButtonText } from "components/button";

function ImageTab({ src }) {
  return (
    <div className={styles.tabHead}>
      <img className={styles.tabImage} src={src} />
    </div>
  );
}

const PlaqueHeatExperience = () => {
  const [tabIndex, setTabIndex] = React.useState(0);
  const currentGoal = useSelector((state) => state.appReducer.currentGoal);
  const dispatch = useDispatch();
  let requestID;
  let camera;
  let controls;
  let scene;
  let renderer;
  const clock = React.useMemo(() => new THREE.Clock(), []);
  let mixer;
  const tabsRef = React.useRef();

  function animate() {
    requestID = requestAnimationFrame(animate);
    const delta = clock.getDelta();
    if (mixer) mixer.update(delta);
    renderer.render(scene, camera);
  }

  function onWindowResize() {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight);
  }

  React.useEffect(() => {
    init();
    animate();
    return function cleanup() {
      window.removeEventListener("resize", onWindowResize);
      window.cancelAnimationFrame(requestID);
      controls.dispose();
    };
  }, []);

  // Scroll to center current tab on tab change
  React.useEffect(() => {
    if (!tabsRef.current) return;
    const tabSelected = tabsRef.current.querySelectorAll(
      '[aria-selected="true"]'
    )[0];
    if (tabSelected) tabSelected.scrollIntoView(false);
  }, [tabIndex]);

  function init() {
    const container = document.getElementById("threeContainer");
    camera = new THREE.PerspectiveCamera(
      50,
      window.innerWidth / window.innerHeight,
      1,
      2000
    );
    camera.position.set(600, 40, 400);

    scene = new THREE.Scene();
    scene.background = new THREE.Color(0x021016);
    const loader = new GLTFLoader();
    loader.load(
      "models/gltf/PlateHeatExchanger_v1.8.gltf",
      function (gltf) {
        mixer = new THREE.AnimationMixer(gltf.scene);
        const action = mixer.clipAction(gltf.animations[0]);
        action.play();
        scene.add(gltf.scene);
      },
      (error) => {
        console.log("gLTF loader error" + error);
      }
    );

    renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.shadowMap.enabled = true;
    container.appendChild(renderer.domElement);

    controls = new OrbitControls(camera, renderer.domElement);
    controls.target.set( 100, -50, -100 );
    controls.update();

    window.addEventListener("resize", onWindowResize, false);
  }

  function handleClose() {
    dispatch({
      type: "UNLOCK_GOAL",
    });
    dispatch({
      type: "GO_TO_SCENE_BY_ID",
      sceneId: currentGoal.unlockTarget,
    });
  }

  const TabTitles = [
    "Flux d’eau du River Cooling entrant",
    "Dérivation de l'eau chaude",
    "Dérivation de l'eau froide",
    "Flux d'eau mélangés",
    "Vue d'ensemble",
  ];

  return (
    <Drawer
      isOpen={true}
      onLeftClick={handleClose}
      name="Comment sont refroids les serveurs"
    >
      <div ref={tabsRef} className={`${styles.tabs} ${styles.tabsFull}`}>
        <TabsContainer
          tabIndex={tabIndex}
          setTabIndex={setTabIndex}
          inverse
          tabs={() => (
            <>
              {TabTitles.map((t, i) => (
                <Tab key={`tabTitle-${i}`}>{t}</Tab>
              ))}
            </>
          )}
        >
          <TabPanel>
            <ImageTab src="/images/phe/phe1.png" />
            <div className={styles.contentView}>
              <h3>
                Flux d’<em>eau du River Cooling</em> entrant
              </h3>
              <p>
                L'échangeur à plaque est un équipement passif permettant au
                River Cooling d'avoir un coefficient de performance de 60, soit
                30 fois plus performant qu'un groupe froid. Pour 100 kWh
                consommé, il produit 6.000kwh thermique.
              </p>
            </div>
          </TabPanel>
          <TabPanel>
            <ImageTab src="/images/phe/phe2.png" />
            <div className={styles.contentView}>
              <h3>
                Dérivation en alternance de l'<strong>eau chaude</strong>
              </h3>
              <p>
                L'échangeur à plaque est un équipement passif permettant au
                River Cooling d'avoir un coefficient de performance de 60, soit
                30 fois plus performant qu'un groupe froid. Pour 100 kWh
                consommé, il produit 6.000kwh thermique.
              </p>
            </div>
          </TabPanel>
          <TabPanel>
            <ImageTab src="/images/phe/phe3.png" />
            <div className={styles.contentView}>
              <h3>
                Dérivation en alternance de l'<em>eau froide</em>
              </h3>
              <p>
                L'échangeur à plaque est un équipement passif permettant au
                River Cooling d'avoir un coefficient de performance de 60, soit
                30 fois plus performant qu'un groupe froid. Pour 100 kWh
                consommé, il produit 6.000kwh thermique.
              </p>
            </div>
          </TabPanel>
          <TabPanel>
            <ImageTab src="/images/phe/phe4.png" />
            <div className={styles.contentView}>
              <h3>
                Flux d’<strong>eau mélangés</strong>
              </h3>
              <p>
                L'échangeur à plaque est un équipement passif permettant au
                River Cooling d'avoir un coefficient de performance de 60, soit
                30 fois plus performant qu'un groupe froid. Pour 100 kWh
                consommé, il produit 6.000kwh thermique.
              </p>
            </div>
          </TabPanel>
          <TabPanel>
            <div className={styles.viewerTab}>
              <ViewerTutorial />
              <div id="threejs" className={styles.viewerFull}>
                <div id="threeContainer" className={styles.viewerThree}></div>
              </div>
            </div>
          </TabPanel>
          <div
            className={`${styles.tabNav} ${
              tabIndex === TabTitles.length - 1 ? styles.lastTab : ""
            }`}
          >
            {tabIndex !== TabTitles.length - 1 ? (
              <>
                <ButtonPrimary full onClick={() => setTabIndex(tabIndex + 1)}>
                  Continuer
                </ButtonPrimary>
                <ButtonText
                  style={{ color: "var(--white)" }}
                  onClick={handleClose}
                >
                  Revenir aux installations
                </ButtonText>
              </>
            ) : (
              <ButtonPrimary full onClick={handleClose}>
                Continuer et retourner aux installations
              </ButtonPrimary>
            )}
          </div>
        </TabsContainer>
      </div>
    </Drawer>
  );
};

export default PlaqueHeatExperience;
