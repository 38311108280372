import Marzipano from "./marzipano";
import React from "react";
import { Provider } from "react-redux";
import Edge from "./components/edge";
import Overlay from "./components/overlay";
import Sensor from "./components/sensor";
import styles from "./App.module.scss";
import Card from "./components/card";
import { MainNavbar } from "components/navbar";
import store from "./store";
import ExperienceSwitcher from "experiences/ExperienceSwitcher";
import Splash from "components/splash";
import IntroScene from "components/intro";
import ClosingScene from "components/closing";
import ClosingCard from "components/closing/ClosingCard";
import TooltipText from "components/tooltipText";

function App() {
  const [showSplash, setShowSplash] = React.useState(true);
  return (
    <Provider store={store}>
      {showSplash && <Splash handleClose={() => setShowSplash(false)} />}

      <Overlay>
        <div className={styles.interface}>
          <MainNavbar />
          <Edge />
          <Sensor />
          {!showSplash && (
            <>
              <TooltipText />
              <Card />
            </>
          )}
        </div>
      </Overlay>
      <div className={styles.viewer}>
        <Marzipano />
        <div className={styles.overlay}></div>
      </div>
      <ExperienceSwitcher />
      <IntroScene />
      <ClosingScene />
      <ClosingCard />
    </Provider>
  );
}

export default App;
