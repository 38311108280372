import React from "react";
import styles from "./Card.module.scss";
import { motion } from "framer-motion";
import { ButtonPrimary, ButtonText } from "../button";
import PropTypes from "prop-types";
import { HotspotCard } from "components/hotspots";
import { useDispatch, useSelector } from "react-redux";
import IconSwitch, { iconTypes } from "components/iconSwitch";
import { useClickAway } from "react-use";
import IconCloseThin from "assets/svg/IconCloseThin";

export const CardWithHotspot = () => {
  return (
    <div className={`${styles.cardWithHotspot} ${styles.container}`}>
      <HotspotCard />
      <Card />
    </div>
  );
};

let lastGoalId;

// Guide card objectif (avec dépliage possible si goal associé)
const Card = ({ goal, guide, action }) => {
  const isOpen = !!goal?.id && goal.display_goal_card !== "none";
  const [open, setOpen] = React.useState(isOpen);
  const ref = React.useRef(null);

  useClickAway(ref, () => {
    if (
      open &&
      !document.getElementById("root").classList.contains("introIsOpen")
    ) {
      console.log("plop");
      setOpen(false);
    }
  });

  const toggleModal = () => {
    if (!goal) return false;
    setOpen((o) => !o);
  };

  React.useEffect(() => {
    if (
      goal.id &&
      lastGoalId !== goal?.id &&
      goal.display_goal_card !== "none"
    ) {
      setOpen(true);
      lastGoalId = goal?.id;
    }
  }, [goal]);

  React.useEffect(() => {
    if (open) {
      document.getElementById("root").classList.add("goalCardIsOpen");
    } else {
      document.getElementById("root").classList.remove("goalCardIsOpen");
    }
    return () => {
      document.getElementById("root").classList.remove("goalCardIsOpen");
    };
  }, [open]);

  return (
    <>
      {open && <div className={styles.cardOverlayOpen}></div>}
      <motion.div
        ref={ref}
        layout
        transition={{ easings: ["easeIn", "easeOut"] }}
        className={`${styles.card} ${open && styles.open}`}
      >
        <motion.button
          transition={{ easings: ["easeIn", "easeOut"] }}
          layout
          className={styles.figure}
          onClick={toggleModal}
        >
          <img src={guide.thumb} alt="miniature objectif" />
          <div
            transition={{ easings: ["easeIn", "easeOut"] }}
            layout
            className={styles.icon}
          >
            <IconSwitch icon={guide.icon} />
          </div>
          {open && (
            <div className={styles.goalInfo}>
              <h4>Objectif</h4>
              <h3>{goal.title}</h3>
            </div>
          )}
        </motion.button>
        {!open ? (
          <div onClick={() => action(guide.target)} className={styles.content}>
            <div className={styles.title}>{guide.title}</div>
            <div className={styles.desc}>{guide.sub}</div>
          </div>
        ) : (
          <>
            <div className={styles.content}>
              <div className={styles.text}>{goal.subtext}</div>
              <div className={styles.center}>
                <ButtonPrimary full onClick={toggleModal}>
                  Continuer
                </ButtonPrimary>
                <ButtonText onClick={() => action(goal.target)}>
                  Accéder directement
                </ButtonText>
              </div>
            </div>
            <button onClick={() => setOpen(false)} className={styles.close}>
              <IconCloseThin />
            </button>
          </>
        )}
      </motion.div>
    </>
  );
};

// Card Action
const CardAction = ({ guide, action }) => {
  return (
    <motion.div className={`${styles.card}`}>
      <motion.button className={styles.figure}>
        <img src={guide.thumb} alt="miniature objectif" />
        <div className={styles.iconAction}>
          <IconSwitch icon={guide.icon} />
        </div>
      </motion.button>
      <div onClick={action} className={styles.content}>
        <div className={styles.title}>{guide.title}</div>
        <div className={styles.desc}>{guide.sub}</div>
      </div>
    </motion.div>
  );
};

// Card déplié en popup quand on est sur la scene du goal.

Card.propTypes = {
  goal: PropTypes.shape({
    goal_id: PropTypes.string.isRequired,
    subtext: PropTypes.string,
    subtext_experience: PropTypes.string,
  }).isRequired,
  guide: PropTypes.shape({
    thumb: PropTypes.string.isRequired,
    icon: PropTypes.oneOf(iconTypes).isRequired,
    title: PropTypes.string.isRequired,
    sub: PropTypes.string.isRequired,
    target: PropTypes.string.isRequired,
  }).isRequired,
  action: PropTypes.func.isRequired,
};

Card.defaultProps = {
  guide: {
    thumb: "/img/default-thumb.jpg",
  },
};

const CardContainer = () => {
  const currentScene = useSelector((state) => state.appReducer.currentScene);
  const dispatch = useDispatch();
  const goals = useSelector((state) => state.appReducer.goals);
  const guide_card = currentScene?.guide_card;
  const currentGoal = goals.find(
    (g) => g.id === currentScene?.goal_card?.goal_id
  );
  if (guide_card?.type === "action") {
    return (
      <div className={styles.container}>
        <CardAction
          guide={guide_card}
          action={() => {
            dispatch({
              type: "START_EXPERIENCE",
              currentGoal: currentGoal,
            });
          }}
        />
      </div>
    );
  }
  return (
    <div className={styles.container}>
      <Card
        action={(target) => {
          dispatch({ type: "GO_TO_SCENE_BY_ID", sceneId: target });
        }}
        goal={{ ...currentScene?.goal_card, ...currentGoal }}
        guide={guide_card}
      />
    </div>
  );
};

export default CardContainer;
