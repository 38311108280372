import React from "react";

function Icon360() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="218"
      height="72"
      viewBox="0 0 218 72"
    >
      <defs>
        <linearGradient id="8n3ihm92ka" x1="100%" x2="0%" y1="50%" y2="50%">
          <stop offset="0%" stopColor="#7DB80E" stopOpacity="0"></stop>
          <stop offset="50.298%" stopColor="#7DB80E"></stop>
          <stop offset="100%" stopColor="#7DB80E" stopOpacity="0"></stop>
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <text
          fill="#092241"
          fontFamily="HelveticaNeue-CondensedBold, Helvetica Neue"
          fontSize="48"
          fontStyle="condensed"
          fontWeight="bold"
          transform="translate(1.5)"
        >
          <tspan x="73" y="46">
            360°
          </tspan>
        </text>
        <path
          stroke="url(#8n3ihm92ka)"
          strokeWidth="2"
          d="M215 24.067C215 53.933 154.708 71 107.672 71S0 51.8 0 23"
          transform="translate(1.5)"
        ></path>
        <path
          stroke="url(#8n3ihm92ka)"
          strokeWidth="2"
          d="M215 24.067C215 53.933 154.708 71 107.672 71S0 51.8 0 23"
          transform="translate(1.5)"
        ></path>
        <path
          fill="#7DB80E"
          d="M51.485 11.515a1 1 0 011.414 1.414L45.828 20l7.071 7.071a1 1 0 01-1.414 1.414l-7.778-7.778a1 1 0 01-.078-1.326l.078-.088 7.778-7.778zM173.6 28.485a1 1 0 01-1.415-1.414L179.256 20l-7.071-7.071a1 1 0 011.414-1.414l7.778 7.778a1 1 0 01.078 1.326l-.078.088-7.778 7.778z"
          opacity="0.5"
        ></path>
      </g>
    </svg>
  );
}

export default Icon360;
