import React from "react";
import styles from "./Modal.module.scss";
import button from "components/button/Button.module.scss";
import { motion } from "framer-motion";
import IconClose from "assets/svg/IconClose";
import { useDispatch } from "react-redux";

const variants = {
  open: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      stiffness: 30,
      restDelta: 1.5,
    },
  },
  closed: {
    opacity: 0,
    y: "100%",
    transition: {
      delay: 0.2,
      type: "spring",
      stiffness: 200,
      damping: 40,
    },
  },
};

const Modal = ({ children }) => {
  const dispatch = useDispatch();
  function handleClose() {
    dispatch({
      type: "END_EXPERIENCE",
    });
  }
  return (
    <>
      <motion.div
        animate={{ opacity: [0, 1] }}
        transition={{
          duration: 0.4,
        }}
        initial={false}
        className={styles.overlay}
      />
      <div className={styles.modal}>
        <div className={styles.centerV}>
          <motion.div
            animate={{ y: [300, 0], opacity: [0, 1], scale: [0.7, 1] }}
            transition={{
              delay: 0.4,
              type: "spring",
              damping: 30,
              stiffness: 400,
            }}
            className={styles.modalContent}
          >
            {children}
          </motion.div>
        </div>
        <motion.button
          animate={{ y: [50, 0], opacity: [0, 1], scale: [0.7, 1] }}
          transition={{
            delay: 0.4,
            type: "spring",
            damping: 30,
            stiffness: 400,
          }}
          className={button.modalButton}
          onClick={handleClose}
        >
          <IconClose />
        </motion.button>
      </div>
    </>
  );
};

export default Modal;
