import React from "react";
import ProgressCircle from "./ProgressCircle";
import styles from "./FingerPrint.module.scss";
import IconAction from "assets/svg/IconAction";

const GearUnlock = ({ handleSetActive, active }) => {
  return (
    <ProgressCircle active={active}>
      <div className={styles.hotspot} onClick={handleSetActive}>
        <IconAction />
      </div>
    </ProgressCircle>
  );
};

export default GearUnlock;
