import React from "react";

function IconClose() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M19.778 5.636a1 1 0 010 1.414L14.828 12l4.95 4.95a1 1 0 010 1.414l-1.414 1.414a1 1 0 01-1.414 0L12 14.828l-4.95 4.95a1 1 0 01-1.414 0l-1.414-1.414a1 1 0 010-1.414L9.17 12 4.22 7.05a1 1 0 010-1.414l1.415-1.414a1 1 0 011.414 0L12 9.17l4.95-4.95a1 1 0 011.414 0l1.414 1.415z"
      ></path>
    </svg>
  );
}

export default IconClose;
