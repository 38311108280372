import IconPinch from "assets/svg/IconPinch";
import IconRotate from "assets/svg/IconRotate";
import { ButtonOutline } from "components/button";
import React from "react";
import { useToggle } from "react-use";
import styles from "./ViewerTutorial.module.scss";

const ViewerTutorial = () => {
  const [show, toggle] = useToggle(true);

  if (!show) return null;
  return (
    <div className={styles.overlay}>
      <IconRotate />
      <IconPinch />
      <ButtonOutline onClick={toggle}>J'ai compris</ButtonOutline>
    </div>
  );
};

export default ViewerTutorial;
