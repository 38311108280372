import React from "react";

function IconInfo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M8.546 11.48l-.302.2a.546.546 0 00-.154.754l.036.056c.116.176.354.2.514.242.136.008.27.031.4.07.231.08.428.237.558.444.2.344.07.768.022.968-.428 1.756-.866 3.51-1.286 5.27-.35 1.466.48 2.516 2 2.516 1.686 0 3.2-.556 4.584-1.496.096-.066.18-.147.252-.24.272-.358.168-.632-.27-.732-.128-.03-.26-.052-.386-.086-.834-.226-1.05-.6-.846-1.432.434-1.77.878-3.538 1.302-5.31.284-1.174-.258-2.138-1.342-2.4a4.001 4.001 0 00-.9-.096 8.711 8.711 0 00-1.116.12 8.253 8.253 0 00-.654.148 9.962 9.962 0 00-1.132.38c-.224.09-.508.212-.83.37l-.45.254zM13.77 2h-.064a2.813 2.813 0 00-2.8 2.8v.053a2.864 2.864 0 002.85 2.85 2.863 2.863 0 002.85-2.824v-.044A2.848 2.848 0 0013.772 2h-.002z"
      ></path>
    </svg>
  );
}

export default IconInfo;
