import React from "react";
import styles from "../coldCorridor/ColdCorridor.module.scss";
import button from "components/button/Button.module.scss";
import Drawer from "components/drawer";
import { useSelector, useDispatch } from "react-redux";
import IconClose from "assets/svg/IconClose";
import IconPlay from "assets/svg/IconPlay";
import { useFullscreen, useToggle } from "react-use";

const SettlingBassin = () => {
  const currentGoal = useSelector((state) => state.appReducer.currentGoal);
  const dispatch = useDispatch();
  const videoEl = React.useRef();
  const [show, toggle] = useToggle(false);
  const isFullscreen = useFullscreen(videoEl, show, {
    onClose: () => {
      videoEl.current.pause();
      toggle(false);
    },
  });

  React.useEffect(() => {
    if (!show) return false;
    videoEl.current.play();
  }, [show]);

  function handleClose() {
    dispatch({
      type: "UNLOCK_GOAL",
    });
    dispatch({
      type: "GO_TO_SCENE_BY_ID",
      sceneId: currentGoal.unlockTarget,
    });
  }

  return (
    <Drawer isOpen={true} onLeftClick={handleClose} name="Galerie de la Mer">
      <div className={styles.viewerFull}>
        <div className={styles.viewerVideo}>
          {!show ? (
            <button onClick={() => toggle()} className={styles.viewerPlay}>
              <IconPlay />
            </button>
          ) : null}
          <video
            ref={videoEl}
            controls={false}
            className={styles.videoFullPlayer}
          >
            <source src="/video/SeaGalery_Pool.mp4" type="video/mp4" />
          </video>
        </div>
      </div>
      <div className={styles.bottom}>
        <button className={button.modalButton} onClick={handleClose}>
          <IconClose />
        </button>
      </div>
    </Drawer>
  );
};

export default SettlingBassin;
