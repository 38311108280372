import React from "react";
import styles from "./ColdCorridor.module.scss";
import { Tab, TabPanel } from "@reach/tabs";
import TabsContainer from "components/tabs";
import Drawer from "components/drawer";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import "./main.scss";
import { useSelector, useDispatch } from "react-redux";
import ColdCorridorContent from "./Content";
import ViewerTutorial from "./ViewerTutorial";
import { ButtonPrimary, ButtonText } from "components/button";

function ImageTab({ src }) {
  return (
    <div className={styles.tabHead}>
      <img className={styles.tabImage} src={src} />
    </div>
  );
}

const ColdCorridorExperience = () => {
  const [tabIndex, setTabIndex] = React.useState(0);
  const currentGoal = useSelector((state) => state.appReducer.currentGoal);
  const dispatch = useDispatch();
  let requestID;
  let camera;
  let controls;
  let scene;
  let renderer;
  const clock = React.useMemo(() => new THREE.Clock(), []);
  let mixer;
  const tabsRef = React.useRef();

  function animate() {
    requestID = requestAnimationFrame(animate);
    const delta = clock.getDelta();
    if (mixer) mixer.update(delta);
    renderer.render(scene, camera);
  }

  function onWindowResize() {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight);
  }

  React.useEffect(() => {
    init();
    animate();
    return function cleanup() {
      window.removeEventListener("resize", onWindowResize);
      window.cancelAnimationFrame(requestID);
      controls.dispose();
    };
  }, []);

  // Scroll to center current tab on tab change
  React.useEffect(() => {
    if (!tabsRef.current) return;
    const tabSelected = tabsRef.current.querySelectorAll(
      '[aria-selected="true"]'
    )[0];
    if (tabSelected) tabSelected.scrollIntoView(false);
  }, [tabIndex]);

  function init() {
    const container = document.getElementById("threeContainer");

    camera = new THREE.PerspectiveCamera(
      50,
      window.innerWidth / window.innerHeight,
      1,
      2000
    );
    camera.position.set( 300, 700, -900 );

    scene = new THREE.Scene();
    scene.background = new THREE.Color(0x021016);
    const loader = new GLTFLoader();
    loader.load(
      "models/gltf/ColdCorridor_XP_v2.8.gltf",
      function (gltf) {
        mixer = new THREE.AnimationMixer(gltf.scene);
        const action = mixer.clipAction(gltf.animations[0]);
        action.play();
        scene.add(gltf.scene);
      },
      (error) => {
        console.log("gLTF loader" + error);
      }
    );

    renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.shadowMap.enabled = true;
    container.appendChild(renderer.domElement);

    controls = new OrbitControls(camera, renderer.domElement);
    controls.target.set( 0, 100, 0 );
    controls.update();

    window.addEventListener("resize", onWindowResize, false);
  }

  function handleClose() {
    dispatch({
      type: "UNLOCK_GOAL",
    });
    dispatch({
      type: "GO_TO_SCENE_BY_ID",
      sceneId: currentGoal.unlockTarget,
    });
  }

  const TabTitles = [
    "Acheminement de l'air froid",
    "Air froid soufflé",
    "Rejet de l'air chaud",
    "Récupération de l’air chaud",
    "Acheminement de l'eau",
    "Vue d'ensemble",
  ];

  return (
    <Drawer
      isOpen={true}
      onLeftClick={handleClose}
      name="Comment sont refroids les serveurs"
    >
      <div ref={tabsRef} className={`${styles.tabs} ${styles.tabsFull}`}>
        <TabsContainer
          tabIndex={tabIndex}
          setTabIndex={setTabIndex}
          inverse
          tabs={() => (
            <>
              {TabTitles.map((t, i) => (
                <Tab key={`tabTitle-${i}`}>{t}</Tab>
              ))}
            </>
          )}
        >
          <TabPanel>
            <ImageTab src="/images/cold_corridor/cold-corridor-1.png" />
            <div className={styles.contentView}>
              <h3>
                Acheminement de l'<strong>air froid</strong>
              </h3>
              <p>
                L’air froid est acheminé sous le plancher et remonte par des
                grilles de ventilation dans le cold-corridor.
              </p>
            </div>
          </TabPanel>
          <TabPanel>
            <ImageTab src="/images/cold_corridor/cold-corridor-2.png" />
            <ColdCorridorContent />
          </TabPanel>
          <TabPanel>
            <ImageTab src="/images/cold_corridor/cold-corridor-3.png" />
            <div className={styles.contentView}>
              <h3>
                Rejet de l'<strong>air chaud</strong>
              </h3>
              <p>
                L’air s’est réchauffé au contact des serveurs pour les
                refroidir. Il est ensuite diffusé en dehors du cold-corridor
                dans la salle, puis aspiré par les armoires de climatisation.
              </p>
            </div>
          </TabPanel>
          <TabPanel>
            <ImageTab src="/images/cold_corridor/cold-corridor-4.png" />
            <div className={styles.contentView}>
              <h3>
                Récupération de l'<strong>air chaud</strong>
              </h3>
              <p>
                L’armoire de climatisation permet l’échange de chaleur dans la
                salle, elle souffle de l’air frais et aspire l’air chaud. Elle
                réalise un échange air/eau et permet ainsi le transfert des
                calories et frigories en dehors de la salle informatique, vers
                la toiture. La production de froid se fait en toiture.
              </p>
            </div>
          </TabPanel>
          <TabPanel>
            <ImageTab src="/images/cold_corridor/cold-corridor-5.png" />
            <div className={styles.contentView}>
              <h3>
                Acheminement de l'<strong>eau</strong>
              </h3>
              <p>
                L’armoire de climatisation permet l’échange de chaleur dans la
                salle, elle souffle de l’air frais et aspire l’air chaud. Elle
                réalise un échange air/eau et permet ainsi le transfert des
                calories et frigories en dehors de la salle informatique, vers
                la toiture. La production de froid se fait en toiture.
              </p>
            </div>
          </TabPanel>
          <TabPanel>
            <div className={styles.viewerTab}>
              <ViewerTutorial />
              <div id="threejs" className={styles.viewerFull}>
                <div id="threeContainer" className={styles.viewerThree}></div>
              </div>
            </div>
          </TabPanel>
          <div
            className={`${styles.tabNav} ${
              tabIndex === TabTitles.length - 1 ? styles.lastTab : ""
            }`}
          >
            {tabIndex !== TabTitles.length - 1 ? (
              <>
                <ButtonPrimary full onClick={() => setTabIndex(tabIndex + 1)}>
                  Continuer
                </ButtonPrimary>
                <ButtonText
                  style={{ color: "var(--white)" }}
                  onClick={handleClose}
                >
                  Revenir au cold-corridor
                </ButtonText>
              </>
            ) : (
              <ButtonPrimary full onClick={handleClose}>
                Continuer et retourner au cold-corridor
              </ButtonPrimary>
            )}
          </div>
        </TabsContainer>
      </div>
    </Drawer>
  );
};

export default ColdCorridorExperience;
