import React from "react";

function IconArrowLeft() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M13.485 3.515a1 1 0 011.414 1.414L7.828 12l7.071 7.071a1 1 0 01-1.414 1.414l-7.778-7.778a1 1 0 01-.078-1.326l.078-.088 7.778-7.778z"
      ></path>
    </svg>
  );
}

export default IconArrowLeft;
