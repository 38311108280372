import React from "react";
import styles from "./Progress.module.scss";

const ProgressCircle = ({ children, active }) => {
  return (
    <div className={styles["progress-container"]}>
      <div className={styles["progress-content"]}>{children}</div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="96"
        height="96"
        fill="none"
        className={styles["progress-circle"]}
        transform="rotate(-90)"
        viewBox="0 0 96 96"
      >
        <circle
          cx="48"
          cy="48"
          r="40"
          fillRule="nonzero"
          stroke="#7DB80E"
          strokeOpacity="0.1"
          strokeWidth="16"
        ></circle>
        <circle
          className={`${active && styles.progress}`}
          cx="48"
          cy="48"
          r="40"
          fillRule="nonzero"
          stroke="#7DB80E"
          strokeDasharray="251.327"
          strokeOpacity="1"
          strokeWidth="16"
          strokeDashoffset="251.327"
        ></circle>
      </svg>
    </div>
  );
};

export default ProgressCircle;
