import { EXPERIENCE_TYPE } from "marzipano/data";
import data from "../marzipano/data";

const initialState = {
  scenes: data.scenes,
  settings: data.settings,
  currentScene: null,
  edge: {},
  goals: data.goals,
  currentExperience: null,
  currentGoal: null,
  experiences: data.experiences,
};

const unlockGoalId = (goals, goalId) => {
  return goals.map((goal) => {
    if (goal.id === goalId) {
      return {
        ...goal,
        status: "unlock",
      };
    }
    return goal;
  });
};

function appReducer(state = initialState, action) {
  switch (action.type) {
    case "INIT_FIRST_SCENE":
      return {
        ...state,
        currentScene: state.scenes[0],
      };
    case "GO_TO_SCENE_BY_ID":
      // Closing SCENE MONKEYPATCH
      if (action.sceneId === "CLOSING") {
        return {
          ...state,
          currentScene: {
            ...state.currentScene,
            id: "CLOSING",
          },
        };
      }
      const currentScene = state.currentScene;
      let newGoals = state.goals;
      if (currentScene?.guide_card?.goal_id) {
        newGoals = unlockGoalId(state.goals, currentScene?.guide_card?.goal_id);
      }
      const nextScene = state.scenes.find((e) => e.id === action.sceneId);
      return {
        ...state,
        currentScene: {
          ...nextScene,
        },
        goals: newGoals,
      };
    case "SET_EDGE_POSITION":
      return {
        ...state,
        edge: action.edge,
      };
    case "START_EXPERIENCE":
      return {
        ...state,
        currentExperience: action.currentGoal.experience_id,
        currentGoal: action.currentGoal,
      };
    case "END_EXPERIENCE":
      return {
        ...state,
        currentExperience: null,
        currentGoal: null,
      };
    case "UNLOCK_GOAL":
      // Specify a goalId or use current goal
      // At the end we reset currentGoal and currentExperience
      const goalId = action.goalId || state.currentGoal.id;
      const goals = unlockGoalId(state.goals, goalId);
      return {
        ...state,
        goals,
        currentGoal: null,
        currentExperience: null,
      };
    case "RESET":
      return initialState;
    default:
      return state;
  }
}

export default appReducer;
