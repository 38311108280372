import IconAction from "assets/svg/IconAction";
import IconDot from "assets/svg/IconDot";
import IconDoubleDown from "assets/svg/IconDown";
import IconInfo from "assets/svg/IconInfo";
import IconLock from "assets/svg/IconLock";
import IconPoi from "assets/svg/IconPoi";
import IconPoiFinal from "assets/svg/IconPoiFinal";
import IconDoubleUp from "assets/svg/IconUp";
import React from "react";
import PropTypes from "prop-types";
import IconCheck from "assets/svg/IconCheck";

export const iconTypes = [
  "lock",
  "up",
  "marker",
  "gear",
  "dot",
  "info",
  "doubleup",
  "doubledown",
  "checked",
];

const IconSwitch = ({ icon }) => {
  switch (icon) {
    case "lock":
      return <IconLock />;
    case "info":
      return <IconInfo />;
    case "gear":
      return <IconAction />;
    case "marker":
      return <IconPoiFinal />;
    case "up":
      return <IconPoi style={{ transform: "rotate(-90deg)" }} />;
    case "dot":
      return <IconDot />;
    case "doubleup":
      return <IconDoubleUp />;
    case "doubledown":
      return <IconDoubleDown />;
    case "checked":
      return <IconCheck />;
    default:
      return <IconPoiFinal />;
  }
};

IconSwitch.propTypes = {
  icon: PropTypes.oneOf(iconTypes),
};

export default IconSwitch;
