import React from "react";

function IconAction() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M20.47 10.25l-2.006-.341c-.114-.36-.265-.7-.436-1.041l1.174-1.647a.629.629 0 00-.057-.795l-1.609-1.609a.606.606 0 00-.795-.057l-1.646 1.174a6.45 6.45 0 00-1.041-.436l-.322-1.987A.61.61 0 0013.126 3h-2.271a.61.61 0 00-.606.511l-.34 2.006c-.36.114-.7.265-1.041.436L7.22 4.779a.629.629 0 00-.795.057L4.817 6.445a.606.606 0 00-.057.795l1.174 1.646a6.45 6.45 0 00-.436 1.041l-1.987.322a.61.61 0 00-.511.606v2.271c0 .303.208.55.511.606l2.006.34c.114.36.265.7.436 1.042L4.779 16.76a.629.629 0 00.057.795l1.609 1.609a.606.606 0 00.795.057l1.646-1.174c.322.17.682.322 1.041.436l.341 2.006a.61.61 0 00.606.511h2.271a.61.61 0 00.606-.511l.322-2.025c.36-.114.7-.265 1.04-.436l1.647 1.174c.246.17.587.151.795-.057l1.609-1.609a.606.606 0 00.057-.795l-1.174-1.646a6.45 6.45 0 00.436-1.041l2.006-.341a.61.61 0 00.511-.606v-2.252a.645.645 0 00-.53-.606zm-8.48 4.977a3.234 3.234 0 01-3.236-3.236 3.235 3.235 0 013.237-3.237 3.235 3.235 0 013.236 3.237 3.222 3.222 0 01-3.236 3.236z"
      ></path>
    </svg>
  );
}

export default IconAction;
