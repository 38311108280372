import React from "react";

function IconArrowUp() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M20.487 14.485a1 1 0 11-1.414 1.414l-7.071-7.071-7.071 7.071a1 1 0 01-1.414-1.414l7.778-7.778a1 1 0 011.327-.079l.087.079 7.778 7.778z"
      ></path>
    </svg>
  );
}

export default IconArrowUp;
