import IconCloseThin from "assets/svg/IconCloseThin";
import { ButtonPrimary } from "components/button";
import IconSwitch from "components/iconSwitch";
import React from "react";
import styles from "./CardExperience.module.scss";

const CardExperience = ({ handleClose, handleContinue, experience }) => {
  React.useEffect(() => {
    document.getElementById("root").classList.add("goalCardIsOpen");
    return () => {
      document.getElementById("root").classList.remove("goalCardIsOpen");
    };
  }, []);

  return (
    <div className={styles.modal}>
      <div className={styles.close} onClick={handleClose}>
        <IconCloseThin />
      </div>
      <div className={styles.header}>
        <img src={experience.thumb} alt="Miniature experience" />
        <div className={styles.headerContent}>
          <div className={styles.type}>{experience.type}</div>
          <h3>{experience.title}</h3>
        </div>
        <div className={styles.icon}>
          <IconSwitch icon={experience.icon} />
        </div>
      </div>
      <div className={styles.content}>
        <p>{experience.subtext}</p>
        <ButtonPrimary full onClick={handleContinue}>
          {experience.button}
        </ButtonPrimary>
      </div>
    </div>
  );
};

export default CardExperience;
