export const EXPERIENCE_TYPE = {
  SAS_UNLOCK: "experience/sas-unlock",
  COLD_CORRIDOR: "experience/cold-corridor",
  COLD_UNIT_PRESENTATION: "experience/cold_unit_presentation",
  PLATE_HEAT_EXCHANGER: "experience/plate_heat_exchanger",
  RIVER_COOLING_WATER: "experience/river-cooling-water",
  COLD_WATER_DISCOVERY: "experience/cold_water_discovery",
  ROOFTOP_VALVE_OPENING: "experience/rooftop_valve_opening",
  SETTLING_BASSIN: "experience/settling_bassin",
  WATER_PUMPING: "experience/water_pumping",
};

const APP_DATA = {
  goals: [
    {
      id: "datacenter_entry",
      title: "Entrer dans le data-center",
      subtext: "Aller dans le hall d'accueil.",
      experience_id: "",
      dependencies: [""],
      target: "4-1_2020-06-12_100256_00007-pano",
      unlockTarget: "6-1_2020-10-13_101919_00000-pano",
      status: "locked",
      thumb: "/card_thumbs/Preview_1_Hall_Entrance.jpg",
      icon: "marker",
    },
    {
      id: "securezone_entry",
      title: "Entrer dans la zone sécurisée du data-center",
      subtext:
        "Passer le sas de sécurité en présentant votre empreinte digitale.",
      experience_id: EXPERIENCE_TYPE.SAS_UNLOCK,
      dependencies: [""],
      target: "7-2_2020-10-13_101919_00008-pano",
      unlockTarget: "8-2_2020-10-13_101919_00008-pano_2",
      status: "locked",
      thumb: "/card_thumbs/Preview_2_SecurityDoor_Door.jpg",
      icon: "marker",
    },
    {
      id: "go_corridor",
      title: "Traverser le sas pour entrer dans le couloir",
      subtext: "Rejoindre la zone sécurisée du data-center.",
      experience_id: "",
      dependencies: [""],
      target: "10-3_2020-10-13_110346_00001-pano",
      unlockTarget: "",
      status: "locked",
      thumb: "/card_thumbs/Preview_2_SecurityDoor_Door.jpg",
      icon: "marker",
      display_goal_card: "none",
    },
    {
      id: "servers_cooling",
      title: "Comment sont refroidis les serveurs ?",
      subtext: "Trouver le cold corridor dans la salle des serveurs.",
      experience_id: EXPERIENCE_TYPE.COLD_CORRIDOR,
      dependencies: [""],
      target: "16-4_2020-10-13_121439_00006-pano",
      unlockTarget: "18-4_2020-10-13_121439_00010-pano_2",
      status: "locked",
      thumb:
        "/card_thumbs/Preview_4_ServersRoom-ColdCorridorDoor_Experience.jpg",
      icon: "marker",
    },
    {
      id: "cold_unit_discovery",
      title: "Comment est refroidie l'eau sans le River Cooling ?",
      subtext: "Aller aux groupes froids",
      experience_id: EXPERIENCE_TYPE.COLD_UNIT_PRESENTATION,
      dependencies: [""],
      target: "23-5_2020-10-14_091056_00023-pano",
      unlockTarget: "23-5_2020-10-14_091056_00023-pano_2",
      status: "locked",
      thumb: "/card_thumbs/Preview_5_Rooftop_CoolingMachine.jpg",
      icon: "marker",
    },
    {
      id: "valve_opening",
      title: "Dériver le circuit de refroidissement",
      subtext: "Identifier la vanne et l'actionner",
      experience_id: EXPERIENCE_TYPE.ROOFTOP_VALVE_OPENING,
      dependencies: [""],
      target: "23-5_2020-10-14_091056_00023-pano_2",
      unlockTarget: "23-5_2020-10-14_091056_00023-pano_3",
      status: "locked",
      thumb: "/card_thumbs/Preview_5_Rooftop_PipeHandle.jpg",
      icon: "marker",
    },
    {
      id: "plate_heat_exchanger_discovery",
      title: "Comment est refroidie l'eau acheminée ?",
      subtext:
        "Trouver les tuyaux d'acheminement et monter sur le toit pour y trouver l'échanger à plaque.",
      experience_id: EXPERIENCE_TYPE.PLATE_HEAT_EXCHANGER,
      dependencies: [""],
      target: "23-5_2020-10-14_091056_00023-pano_3",
      unlockTarget: "23-5_2020-10-14_091056_00023-pano_texts",
      status: "locked",
      thumb: "/card_thumbs/Preview_5_Rooftop-PlateHeatExchanger_Experience.jpg",
      icon: "marker",
    },
    {
      id: "river_cooling_water",
      title: "D'où provient l'eau du River Cooling ?",
      subtext:
        "Direction Marseille vue du ciel pour découvrir la provenance de l'eau du River Cooling.",
      experience_id: "",
      dependencies: [""],
      target: "29-7_2020-10-13_154432_00037-pano",
      status: "locked",
      thumb: "/card_thumbs/Preview_6_SkyView_MRS2.jpg",
      icon: "marker",
    },
    {
      id: "cold_water_discovery",
      title: "Découvrir l'eau naturellement froide du River Cooling",
      subtext: "Redescendre la galerie pour atteindre le bassin.",
      experience_id: EXPERIENCE_TYPE.SETTLING_BASSIN,
      dependencies: [""],
      target: "32-7_2020-10-13_154432_00017-pano",
      unlockTarget: "33-7_2020-10-13_154432_00017-pano_2",
      status: "locked",
      thumb: "/card_thumbs/Preview_7_SeaGalery_Pool.jpg",
      icon: "marker",
    },
    {
      id: "water_pumping",
      title: "Comment l'eau est acheminée jusqu'au data-center ?",
      subtext: "Redescendre la galerie jusqu'à la station de pompage.",
      experience_id: EXPERIENCE_TYPE.WATER_PUMPING,
      dependencies: [""],
      target: "36-8_2020-10-13_164209_00004-pano",
      unlockTarget: "37-8_2020-10-13_164209_00011-pano",
      status: "locked",
      thumb: "/card_thumbs/Preview_8_PupmingStation_Installation.jpg",
      icon: "marker",
    },
    {
      id: "river_cooling_water_loop",
      title: "Quel est le parcours de l'eau du River Cooling ?",
      subtext:
        "Direction Marseille vue du ciel pour découvrir le parcours global de l’eau.",
      experience_id: "",
      dependencies: [""],
      target: "41-6_100_0001_f",
      status: "locked",
      thumb: "/card_thumbs/Preview_6_SkyView_MRS2.jpg",
      icon: "marker",
    },
  ],
  experiences: [
    /* 
    Expérience mineure, pas utile de présenter une fenêtre experience card avant…
    {
      icon: "info",
      title: "",
      subtext: "",
      thumb: "/card_thumbs/",
      experience_id: EXPERIENCE_TYPE.SAS_UNLOCK,
    },
    */
    {
      type: "Expérience",
      icon: "info",
      title: "Comment sont refroidis les serveurs ?",
      subtext:
        "Découvrir comment est acheminé l'air froid qui refroidi les serveurs dans leurs racks.",
      button: "Découvrir l'expérience",
      thumb:
        "/card_thumbs/Preview_4_ServersRoom-ColdCorridorDoor_Experience.jpg",
      experience_id: EXPERIENCE_TYPE.COLD_CORRIDOR,
    },
    {
      type: "Fiche",
      icon: "info",
      // TODO Emeric, textes à faire valider (title + subtext)
      title: "Les groupes froid",
      subtext:
        "Découvrir ce qu'est un groupe froid et ce qu'il permet de faire.",
      thumb: "/card_thumbs/Preview_5_Rooftop_CoolingMachine.jpg",
      button: "Découvrir la fiche",
      experience_id: EXPERIENCE_TYPE.COLD_UNIT_PRESENTATION,
      display_experience_card: "none",
    },
    {
      type: "Expérience",
      icon: "info",
      title: "Le principe de refroidissement du River Cooling",
      subtext:
        "Découvrir comment l'échangeur à plaques permet de refroidir l'eau réchauffée issue de la salle des serveurs.",
      // TODO Emeric, manque l'image de la carte expérience Échangeur à plaques
      thumb: "/card_thumbs/Preview_5_Rooftop-PlateHeatExchanger_Experience.jpg",
      button: "Découvrir l'expérience",
      experience_id: EXPERIENCE_TYPE.PLATE_HEAT_EXCHANGER,
    },
    {
      type: "Action",
      icon: "info",
      title: "Dérivez le circuit de refroidissement",
      subtext:
        "Nous vous avons présenté deux systèmes de refroidissement, mettez en marche le circuit vers le River Cooling pour de réels gains d’énergie.",
      thumb: "/card_thumbs/Preview_5_Rooftop_PipeHandle.jpg",
      button: "Mettre en marche",
      experience_id: EXPERIENCE_TYPE.ROOFTOP_VALVE_OPENING,
    },
    {
      type: "Vidéo",
      icon: "info",
      title: "L'eau naturelle à 15°C provenant de la mine de Gardanne",
      subtext: "",
      thumb: "/card_thumbs/Preview_7_SeaGalery_Pool.jpg",
      button: "Découvrir la vidéo",
      experience_id: EXPERIENCE_TYPE.SETTLING_BASSIN,
    },
    {
      type: "Fiche",
      icon: "info",
      title: "Comment l'eau est pompée et filtrée ?",
      subtext:
        "Découvrir comment l'eau en provenance de la mine de Gardanne est pompée et filtrée avant d'être acheminée vers le data-center.",
      button: "Découvrir la fiche",
      thumb: "/card_thumbs/Preview_8_PupmingStation_Installation.jpg",
      experience_id: EXPERIENCE_TYPE.WATER_PUMPING,
    },
  ],
  scenes: [
    {
      id: "0-0_100_0011_f",
      name: "Extérieur",
      description: "Vue aérienne du bâtiment MRS2 avec vue sur l'entrée",
      guide_card: {
        thumb: "/card_thumbs/Preview_1_Hall_Entrance.jpg",
        icon: "marker",
        title: "Entrer dans le bâtiment MRS2",
        sub: "Toucher pour avancer",
        target: "1-0_2020-10-13_134517_00008-pano",
        goal_id: "datacenter_entry",
        note:
          "Emeric > ouvrir la guide card sur l'objectif si goal_id et l'objectif différent du current, donc pas utile d'avoir un state",
      },
      goal_card: {
        goal_id: "datacenter_entry",
        subtext: "Aller dans le hall d'accueil.",
        subtext_experience: "",
      },
      levels: [
        {
          tileSize: 256,
          size: 256,
          fallbackOnly: true,
        },
        {
          tileSize: 512,
          size: 512,
        },
        {
          tileSize: 512,
          size: 1024,
        },
        {
          tileSize: 512,
          size: 2048,
        },
        {
          tileSize: 512,
          size: 4096,
        },
      ],
      faceSize: 4092.5,
      initialViewParameters: {
        yaw: 2.5156815249886506,
        pitch: 0.36076047389730803,
        fov: 1.5707963267948966,
      },
      linkHotspots: [],
      unusedHotspots: [
        {
          yaw: -0.7049854198366141,
          pitch: -0.030267693438013055,
          title: "Vers la galerie à la mer",
          state: "locked",
          target: "",
        },
      ],
      infoHotspots: [
        {
          icon: "up",
          yaw: 2.3072997943359326,
          pitch: 0.43642775264265055,
          target: "1-0_2020-10-13_134517_00008-pano",
        },
      ],
      tooltips: [
        {
          yaw: 2.497686357216116,
          pitch: 0.1645737738800399,
          title: "Data-center MRS2",
        },
        {
          yaw: -0.5119128638150201,
          pitch: -0.003321363592668547,
          title: "Station de pompage",
        },
      ],
    },
    {
      id: "1-0_2020-10-13_134517_00008-pano",
      name: "Extérieur",
      description:
        "Vue depuis le sol du parking face au bâtiment MRS2 avec vue sur l'entrée",
      guide_card: {
        thumb: "/card_thumbs/Preview_1_Hall_Entrance.jpg",
        icon: "marker",
        title: "Entrer dans le bâtiment MRS2",
        sub: "Toucher pour avancer",
        target: "2-0_2020-10-13_134517_00011-pano",
        goal_id: "datacenter_entry",
      },
      goal_card: {
        goal_id: "datacenter_entry",
        subtext: "Aller dans le hall d'accueil.",
        subtext_experience: "",
      },
      levels: [
        {
          tileSize: 256,
          size: 256,
          fallbackOnly: true,
        },
        {
          tileSize: 512,
          size: 512,
        },
        {
          tileSize: 512,
          size: 1024,
        },
        {
          tileSize: 512,
          size: 2048,
        },
      ],
      faceSize: 2048,
      initialViewParameters: {
        yaw: -0.06364701386382876,
        pitch: -0.008892868564597478,
        fov: 1.6602887897041279,
      },
      linkHotspots: [],
      unusedHotspots: [
        {
          yaw: 2.7137428084157236,
          pitch: -0.837474642723965,
          rotation: 3.141592653589793,
          target: "0-0_100_0011_f",
        },
      ],
      infoHotspots: [
        {
          icon: "up",
          id: "parking_entry_ground",
          yaw: -0.038736678447113704,
          pitch: 0.07851451588106961,
          target: "2-0_2020-10-13_134517_00011-pano",
        },
      ],
    },
    {
      id: "2-0_2020-10-13_134517_00011-pano",
      name: "Extérieur",
      description:
        "Vue à proximité de la porte d'entrée (fermée) du bâtiment MRS2",
      guide_card: {
        thumb: "/card_thumbs/Preview_1_Hall_Entrance.jpg",
        icon: "marker",
        title: "Entrer dans le bâtiment MRS2",
        sub: "Toucher pour avancer",
        target: "4-1_2020-06-12_100256_00007-pano",
        goal_id: "datacenter_entry",
      },
      goal_card: {
        goal_id: "datacenter_entry",
        subtext: "Aller dans le hall d'accueil.",
        subtext_experience: "",
      },
      levels: [
        {
          tileSize: 256,
          size: 256,
          fallbackOnly: true,
        },
        {
          tileSize: 512,
          size: 512,
        },
        {
          tileSize: 512,
          size: 1024,
        },
        {
          tileSize: 512,
          size: 2048,
        },
      ],
      faceSize: 2048,
      initialViewParameters: {
        yaw: 0.010314899999997351,
        pitch: 0,
        fov: 1.6602887897041279,
      },
      linkHotspots: [],
      unusedHotspots: [],
      infoHotspots: [
        {
          icon: "up",
          id: "entry_door",
          yaw: -0.03847448509660545,
          pitch: 0.007803026555000869,
          target: "4-1_2020-06-12_100256_00007-pano",
        },
      ],
    },
    {
      id: "4-1_2020-06-12_100256_00007-pano",
      name: "Entrée principale MRS2",
      description: "Vue de l'accueil MRS2",
      guide_card: {
        thumb: "/card_thumbs/Preview_2_SecurityDoor_Door.jpg",
        icon: "marker",
        title: "Avancer jusqu'au sas de sécurité",
        sub: "15 m",
        target: "6-1_2020-10-13_101919_00000-pano",
      },
      goal_card: {
        goal_id: "securezone_entry",
        subtext: "Identifier le sas d'entrée.",
        subtext_experience: "",
      },
      place_intro: {
        title: "Hall d'accueil MRS2",
        text:
          "Les data-centers sont au cœur de l'économie numérique. Sans eux, pas d’Internet !<br><br>Les data-centers permettent les usages digitaux d’aujourd’hui et de demain : télémédecine, réseaux sociaux, accès au cloud, vidéos en ligne, voitures autonomes etc.<br><br>Un data-center a pour vocation d’héberger les infrastructures informatiques des entreprises de tous secteurs d’activité avec un haut niveau de sécurité, tout en garantissant des alimentations électriques redondées et systèmes de refroidissement efficaces, indispensables au bon fonctionnement des serveurs. Les échanges réseaux peuvent alors s’opérer et permettre la création de services numériques de premier plan.",
      },
      levels: [
        {
          tileSize: 256,
          size: 256,
          fallbackOnly: true,
        },
        {
          tileSize: 512,
          size: 512,
        },
        {
          tileSize: 512,
          size: 1024,
        },
        {
          tileSize: 512,
          size: 2048,
        },
      ],
      faceSize: 2048,
      initialViewParameters: {
        yaw: 0.314,
        pitch: 0.0797226144747416,
        fov: 1.6763155324225463,
      },
      linkHotspots: [],
      unusedHotspots: [],
      infoHotspots: [
        {
          icon: "up",
          id: "entrance_lobby",
          yaw: 0.8505395316552242,
          pitch: 0.10853936447456114,
          target: "6-1_2020-10-13_101919_00000-pano",
        },
      ],
    },
    {
      id: "5-1_2020-06-12_100256_00010-pano",
      name: "Zone salon accueil MRS2",
      description:
        "Vue de la zone à gauche de l'accueil MRS2 sur tapis et face TV",
      levels: [
        {
          tileSize: 256,
          size: 256,
          fallbackOnly: true,
        },
        {
          tileSize: 512,
          size: 512,
        },
        {
          tileSize: 512,
          size: 1024,
        },
        {
          tileSize: 512,
          size: 2048,
        },
      ],
      faceSize: 2048,
      initialViewParameters: {
        yaw: -0.9040189086624686,
        pitch: 0.01630640775602643,
        fov: 1.6602887897041279,
      },
      linkHotspots: [],
      unusedHotspots: [],
      infoHotspots: [
        {
          yaw: -1.0518671479468402,
          pitch: -0.38694251336810837,
          title: "tv",
          text: "Text",
        },
      ],
    },
    {
      id: "6-1_2020-10-13_101919_00000-pano",
      name: "Sas de sécurité",
      description:
        "Vue du couloir menant au sas d'entrée dans le data-center MRS2",
      guide_card: {
        thumb: "/card_thumbs/Preview_2_SecurityDoor_Door.jpg",
        icon: "marker",
        title: "Avancer jusqu'au sas de sécurité",
        sub: "5 m",
        target: "7-2_2020-10-13_101919_00008-pano",
      },
      goal_card: {
        goal_id: "securezone_entry",
        subtext: "Identifier le sas d'entrée.",
        subtext_experience: "",
      },
      levels: [
        {
          tileSize: 256,
          size: 256,
          fallbackOnly: true,
        },
        {
          tileSize: 512,
          size: 512,
        },
        {
          tileSize: 512,
          size: 1024,
        },
        {
          tileSize: 512,
          size: 2048,
        },
      ],
      faceSize: 2048,
      initialViewParameters: {
        yaw: -0.4109605938095857,
        pitch: 0.23441205992354774,
        fov: 1.6763155324225463,
      },
      linkHotspots: [],
      unusedHotspots: [],
      infoHotspots: [
        {
          icon: "marker",
          id: "sas_lobby",
          target: "7-2_2020-10-13_101919_00008-pano",
          yaw: -0.3426460612948574,
          pitch: 0.1744230864062679,
        },
      ],
    },
    {
      id: "7-2_2020-10-13_101919_00008-pano",
      name: "Sas de sécurité",
      description:
        "Vue rapprochée du sas d'entrée dans le data-center MRS2 (avant franchissement)",
      guide_card: {
        thumb: "/card_thumbs/Preview_2_SecurityDoor_Door.jpg",
        icon: "lock",
        type: "action",
        title: "Déverrouiller le sas de sécurité",
        sub: "Toucher pour déverrouiller",
        note:
          "Emeric > experience ouverture de sas puis 8-2_2020-10-13_101919_00008-pano_2, voir ouverture aussi depuis le hotspot de la scène",
      },
      goal_card: {
        goal_id: "securezone_entry",
        subtext: "Déverrouiller le sas d'entrée.",
        subtext_experience: "",
      },
      levels: [
        {
          tileSize: 256,
          size: 256,
          fallbackOnly: true,
        },
        {
          tileSize: 512,
          size: 512,
        },
        {
          tileSize: 512,
          size: 1024,
        },
        {
          tileSize: 512,
          size: 2048,
        },
      ],
      faceSize: 2048,
      initialViewParameters: {
        yaw: 0.028197853932525163,
        pitch: 0.3205938702351929,
        fov: 1.6602887897041279,
      },
      linkHotspots: [],
      unusedHotspots: [],
      infoHotspots: [
        {
          icon: "gear",
          id: "fingerprint_reader",
          type: "experience_action",
          yaw: 0.5441479964729083,
          pitch: 0.5959631481744552,
        },
      ],
    },
    {
      id: "8-2_2020-10-13_101919_00008-pano_2",
      name: "Sas de sécurité",
      description:
        "Vue rapprochée du sas d'entrée dans le data-center MRS2 (après empreinte)",
      guide_card: {
        icon: "up",
        title: "Passer le sas de sécurité",
        sub: "Toucher pour avancer",
        thumb: "/card_thumbs/Preview_2_SecurityDoor_Door.jpg",
        target: "10-3_2020-10-13_110346_00001-pano",
      },
      goal_card: {
        goal_id: "go_corridor",
        subtext: "",
        subtext_experience: "",
      },
      note:
        "ici pas de goal id car pas encore définie, il en faut une sinon plantage, si pas de thumb afficher la guide card avec icon au centre du cercle foncé",
      levels: [
        {
          tileSize: 256,
          size: 256,
          fallbackOnly: true,
        },
        {
          tileSize: 512,
          size: 512,
        },
        {
          tileSize: 512,
          size: 1024,
        },
        {
          tileSize: 512,
          size: 2048,
        },
      ],
      faceSize: 2048,
      initialViewParameters: {
        yaw: 0.046238823343065505,
        pitch: 0.3079493390233097,
        fov: 1.6763155324225463,
      },
      linkHotspots: [],
      unusedHotspots: [
        {
          icon: "dot",
          yaw: 0.5485927010567995,
          pitch: 0.6052539581352079,
          target: "9-3_2020-06-12_100256_00033-pano",
        },
      ],
      infoHotspots: [
        {
          icon: "up",
          id: "sas",
          target: "10-3_2020-10-13_110346_00001-pano",
          yaw: 0.17568378426920894,
          pitch: 0.3216448202732156,
        },
      ],
    },
    {
      id: "10-3_2020-10-13_110346_00001-pano",
      name: "Couloir",
      description:
        "Vue du couloir à l'intérieur du data-center MRS2 (transition 1 vers salle serveur, sas encore visible)",
      guide_card: {
        thumb: "/card_thumbs/Preview_4_ServersRoom_ColdCorridorDoor.jpg",
        icon: "marker",
        title: "Avancer tout droit",
        sub: "103 m",
        target: "9-3_2020-06-12_100256_00033-pano",
        goal_id: "servers_cooling",
      },
      goal_card: {
        goal_id: "servers_cooling",
        subtext: "",
        subtext_experience: "",
      },
      levels: [
        {
          tileSize: 256,
          size: 256,
          fallbackOnly: true,
        },
        {
          tileSize: 512,
          size: 512,
        },
        {
          tileSize: 512,
          size: 1024,
        },
        {
          tileSize: 512,
          size: 2048,
        },
      ],
      faceSize: 2048,
      initialViewParameters: {
        yaw: 0.2017745858661968,
        pitch: 0.1586168654449729,
        fov: 1.6602887897041279,
      },
      linkHotspots: [],
      unusedHotspots: [],
      infoHotspots: [
        {
          icon: "up",
          id: "corridor_to_016_1",
          target: "9-3_2020-06-12_100256_00033-pano",
          goalId: "cooling_servers",
          yaw: 0.09066025392153598,
          pitch: 0.08619004855297341,
        },
      ],
    },
    {
      id: "9-3_2020-06-12_100256_00033-pano",
      name: "Couloir",
      description:
        "Vue du couloir à l'intérieur du data-center MRS2 (transition 2 vers salle serveur)",
      guide_card: {
        thumb: "/card_thumbs/Preview_4_ServersRoom_ColdCorridorDoor.jpg",
        icon: "marker",
        title: "Avancer tout droit",
        sub: "81 m",
        target: "11-3_2020-10-13_110346_00004-pano",
        goal_id: "servers_cooling",
      },
      goal_card: {
        goal_id: "servers_cooling",
        subtext: "Déverrouiller le sas d'entrée",
        subtext_experience: "",
      },
      levels: [
        {
          tileSize: 256,
          size: 256,
          fallbackOnly: true,
        },
        {
          tileSize: 512,
          size: 512,
        },
        {
          tileSize: 512,
          size: 1024,
        },
        {
          tileSize: 512,
          size: 2048,
        },
      ],
      faceSize: 2048,
      initialViewParameters: {
        yaw: 0,
        pitch: 0,
        fov: 1.6602887897041279,
      },
      linkHotspots: [],
      unusedHotspots: [],
      infoHotspots: [
        {
          icon: "up",
          id: "corridor_to_016_2",
          target: "11-3_2020-10-13_110346_00004-pano",
          goalId: "cooling_servers",
          yaw: -0.02345602907577593,
          pitch: 0.08202247368461357,
        },
      ],
    },
    {
      id: "11-3_2020-10-13_110346_00004-pano",
      name: "Couloir",
      description:
        "Vue du couloir à l'intérieur du data-center MRS2 (transition 3 vers salle serveur)",
      guide_card: {
        thumb: "/card_thumbs/Preview_4_ServersRoom_ColdCorridorDoor.jpg",
        icon: "marker",
        title: "Avancer tout droit",
        sub: "44 m",
        target: "12-3_2020-06-12_100256_00049-pano",
        goal_id: "servers_cooling",
      },
      goal_card: {
        goal_id: "servers_cooling",
        subtext: "",
        subtext_experience: "",
      },
      levels: [
        {
          tileSize: 256,
          size: 256,
          fallbackOnly: true,
        },
        {
          tileSize: 512,
          size: 512,
        },
        {
          tileSize: 512,
          size: 1024,
        },
        {
          tileSize: 512,
          size: 2048,
        },
      ],
      faceSize: 2048,
      initialViewParameters: {
        yaw: 0.1570862469830736,
        pitch: 0.09487364512595775,
        fov: 1.6602887897041279,
      },
      linkHotspots: [],
      unusedHotspots: [],
      infoHotspots: [
        {
          icon: "up",
          id: "corridor_to_016_3",
          target: "12-3_2020-06-12_100256_00049-pano",
          goalId: "cooling_servers",
          yaw: 0.13166693563337084,
          pitch: 0.061662909107667474,
        },
      ],
    },
    {
      id: "12-3_2020-06-12_100256_00049-pano",
      name: "Couloir",
      description:
        "Vue du couloir à l'intérieur du data-center MRS2 (face à la salle serveur)",
      guide_card: {
        thumb: "/card_thumbs/Preview_4_ServersRoom_ColdCorridorDoor.jpg",
        icon: "marker",
        title: "Entrer dans la salle serveurs",
        sub: "Toucher pour entrer",
        target: "13-4_2020-10-13_121439_00000-pano",
        goal_id: "servers_cooling",
      },
      goal_card: {
        goal_id: "servers_cooling",
        subtext: "",
        subtext_experience: "",
      },
      levels: [
        {
          tileSize: 256,
          size: 256,
          fallbackOnly: true,
        },
        {
          tileSize: 512,
          size: 512,
        },
        {
          tileSize: 512,
          size: 1024,
        },
        {
          tileSize: 512,
          size: 2048,
        },
      ],
      faceSize: 2048,
      initialViewParameters: {
        yaw: -0.7426674220884237,
        pitch: 0.2244341825759495,
        fov: 1.6763155324225463,
      },
      linkHotspots: [],
      unusedHotspots: [],
      infoHotspots: [
        {
          icon: "up",
          id: "welcome_to_016",
          target: "13-4_2020-10-13_121439_00000-pano",
          goalId: "cooling_servers",
          yaw: -0.7927301851483346,
          pitch: 0.37351150704638414,
        },
      ],
    },
    {
      id: "13-4_2020-10-13_121439_00000-pano",
      name: "Salle serveurs",
      description: "Vue de l'intérieur de la salle serveur",
      temperature: 29,
      guide_card: {
        thumb: "/card_thumbs/Preview_4_ServersRoom_ColdCorridorDoor.jpg",
        icon: "marker",
        title: "Avancer jusqu'au cold corridor",
        sub: "9 m",
        target: "14-4_2020-10-13_121439_00017-pano",
        goal_id: "servers_cooling",
      },
      goal_card: {
        goal_id: "servers_cooling",
        subtext: "",
        subtext_experience: "",
      },
      place_intro: {
        title: "Salle des serveurs",
        text:
          "Nous sommes dans une salle blanche équipée de serveurs organisée de manière à compartimenter les flux d’air dans le but de limiter les besoins de refroidissement. Au centre, les serveurs sont rangés dans des armoires climatisées appelées « cold corridor ».",
      },
      levels: [
        {
          tileSize: 256,
          size: 256,
          fallbackOnly: true,
        },
        {
          tileSize: 512,
          size: 512,
        },
        {
          tileSize: 512,
          size: 1024,
        },
        {
          tileSize: 512,
          size: 2048,
        },
      ],
      faceSize: 2048,
      initialViewParameters: {
        yaw: 0.08487655333017585,
        pitch: 0.11094157164490248,
        fov: 1.6763155324225463,
      },
      unusedHotspots: [],
      linkHotspots: [
        {
          icon: "gear",
          id: "to_rooftop",
          tooltip: "Acheminement de l'eau",
          state: "locked",
          yaw: -0.3698331454604453,
          pitch: -0.05207771582757559,
        },
      ],
      infoHotspots: [
        {
          icon: "up",
          id: "into_016",
          target: "14-4_2020-10-13_121439_00017-pano",
          yaw: 0.08491512967665926,
          pitch: 0.12228825108811847,
        },
      ],
      tooltips: [
        {
          yaw: 0.3608360431438662,
          pitch: 0.02266553244137981,
          title: "Cold corridor",
        },
        {
          yaw: -0.22403386119804836,
          pitch: 0.03,
          title: "Armoire de climatisation",
        },
      ],
    },
    {
      id: "14-4_2020-10-13_121439_00017-pano",
      name: "Salle serveurs",
      description:
        "Vue de l'intérieur de la salle serveur face au cold corridor portes fermées",
      temperature: 29,
      note:
        "Emeric > lecteur de badge à câbler puis 15-4_2020-10-13_121439_00016-pano",
      guide_card: {
        thumb: "/card_thumbs/Preview_4_ServersRoom_ColdCorridorDoor.jpg",
        icon: "marker",
        title: "Déverrouiller la porte",
        sub: "Toucher pour déverrouiller",
        target: "15-4_2020-10-13_121439_00016-pano",
        goal_id: "servers_cooling",
      },
      goal_card: {
        goal_id: "servers_cooling",
        subtext: "",
        subtext_experience: "",
      },
      levels: [
        {
          tileSize: 256,
          size: 256,
          fallbackOnly: true,
        },
        {
          tileSize: 512,
          size: 512,
        },
        {
          tileSize: 512,
          size: 1024,
        },
        {
          tileSize: 512,
          size: 2048,
        },
      ],
      faceSize: 2048,
      initialViewParameters: {
        yaw: -1.8347902447525755,
        pitch: 0.3263873390137526,
        fov: 1.6602887897041279,
      },
      linkHotspots: [],
      unusedHotspots: [
        {
          yaw: 0.2399051310458855,
          pitch: 0.06142049749277767,
          title: "wrong way",
          text: "Text",
        },
      ],
      infoHotspots: [
        {
          icon: "gear",
          id: "coldcorridor_fingerprint",
          target: "15-4_2020-10-13_121439_00016-pano",
          note: "Toucher pour déverrouiller le lecteur empreintes",
          goalId: "cooling_servers",
          yaw: -1.3229235262212171,
          pitch: 0.6158626408997723,
        },
      ],
      tooltips: [
        {
          yaw: 1.0584282294387357,
          pitch: 0.35996364604428877,
          title: "Armoire de climatisation",
        },
        {
          yaw: -2.1858629241751295,
          pitch: -0.2051726879044189,
          title: "Cold corridor",
        },
      ],
    },
    {
      id: "15-4_2020-10-13_121439_00016-pano",
      name: "Salle serveurs",
      description: "Vue vers l'intérieur du cold corridor portes ouvertes",
      temperature: 29,
      guide_card: {
        thumb: "/card_thumbs/Preview_4_ServersRoom_ColdCorridorDoor.jpg",
        icon: "marker",
        title: "Entrer dans le cold corridor",
        sub: "Toucher pour entrer",
        target: "16-4_2020-10-13_121439_00006-pano",
        goal_id: "servers_cooling",
      },
      goal_card: {
        goal_id: "servers_cooling",
        subtext: "",
        subtext_experience: "",
      },
      levels: [
        {
          tileSize: 256,
          size: 256,
          fallbackOnly: true,
        },
        {
          tileSize: 512,
          size: 512,
        },
        {
          tileSize: 512,
          size: 1024,
        },
        {
          tileSize: 512,
          size: 2048,
        },
      ],
      faceSize: 2048,
      initialViewParameters: {
        yaw: -1.6671552186092526,
        pitch: 0.2760454073538092,
        fov: 1.9090630671252546,
      },
      linkHotspots: [],
      unusedHotspots: [
        {
          yaw: 0.25406721498930196,
          pitch: 0.07510556597506124,
          title: "wrong way",
        },
      ],
      infoHotspots: [
        {
          icon: "up",
          target: "16-4_2020-10-13_121439_00006-pano",
          yaw: -1.9191203074008296,
          pitch: 0.7331103748696535,
        },
      ],
    },
    {
      id: "16-4_2020-10-13_121439_00006-pano",
      name: "Cold corridor",
      description: "Vue de l'intérieur du cold corridor",
      temperature: 25,
      guide_card: {
        thumb:
          "/card_thumbs/Preview_4_ServersRoom_ColdCorridorDoor_Experience.jpg",
        icon: "info",
        title: "Découvrir l'expérience",
        sub: "Toucher pour découvrir",
        goal_id: "servers_cooling",
        type: "action",
        note:
          "Lancement de carte expérience liée à EXPERIENCE_TYPE.COLD_CORRIDOR",
      },
      /* TODO Emeric
      button: {
        label: "Sortir du cold corridor",
        target: "15-4_2020-10-13_121439_00016-pano",
      },
      */
      goal_card: {
        goal_id: "servers_cooling",
        subtext: "",
        subtext_experience: "",
      },
      levels: [
        {
          tileSize: 256,
          size: 256,
          fallbackOnly: true,
        },
        {
          tileSize: 512,
          size: 512,
        },
        {
          tileSize: 512,
          size: 1024,
        },
        {
          tileSize: 512,
          size: 2048,
        },
      ],
      faceSize: 2048,
      initialViewParameters: {
        yaw: 0.036653550565134196,
        pitch: 0.16077677300519966,
        fov: 1.6602887897041279,
      },
      linkHotspots: [],
      unusedHotspots: [
        {
          yaw: 3.075477906534143,
          pitch: 0.12589524777625627,
          title: "wrong way",
          text: "Text",
        },
      ],
      infoHotspots: [
        {
          icon: "info",
          type: "experience_action",
          goalId: "cooling_servers",
          yaw: 0.013776649788960427,
          pitch: 0.2922661130136337,
        },
      ],
    },
    {
      id: "18-4_2020-10-13_121439_00010-pano_2",
      name: "Cold corridor",
      description:
        "Vue de l'intérieur du cold corridor, retour de l'expérience",
      temperature: 25,
      guide_card: {
        thumb:
          "/card_thumbs/Preview_4_ServersRoom_ColdCorridorDoor_Experience.jpg",
        icon: "marker",
        title: "Sortir du cold corridor",
        sub: "Toucher pour sortir",
        target: "14-4_2020-10-13_121439_00017-pano_color",
      },
      /* TODO Emeric
      button: { label: "Sortir du cold corridor", target: "" },
      */
      goal_card: {
        goal_id: "",
        subtext: "",
        subtext_experience: "",
      },
      levels: [
        {
          tileSize: 256,
          size: 256,
          fallbackOnly: true,
        },
        {
          tileSize: 512,
          size: 512,
        },
        {
          tileSize: 512,
          size: 1024,
        },
        {
          tileSize: 512,
          size: 2048,
        },
      ],
      faceSize: 2048,
      initialViewParameters: {
        yaw: -3.0939916811124544,
        pitch: 0.2717001955264955,
        fov: 1.6763155324225463,
      },
      linkHotspots: [],
      unusedHotspots: [
        {
          icon: "info",
          yaw: -0.005326654209060422,
          pitch: 0.13935879928374106,
          title: "experience",
        },
      ],
      infoHotspots: [
        {
          icon: "up",
          target: "14-4_2020-10-13_121439_00017-pano_color",
          yaw: -3.1365735500718994,
          pitch: 0.12538243267421478,
        },
      ],
      tooltips: [
        {
          yaw: 3.0904635232608255,
          pitch: 0.07375838673462276,
          title: "Armoire de climatisation",
        },
      ],
    },
    {
      id: "14-4_2020-10-13_121439_00017-pano_color",
      name: "Salle serveurs",
      description:
        "Vue de l'intérieur de la salle serveurs, sortie cold corridor",
      temperature: 29,
      guide_card: {
        thumb: "/card_thumbs/Preview_4_ServersRoom_Pipes.jpg",
        icon: "marker",
        title: "Avancer jusqu'aux tuyaux d'acheminement de l'eau",
        sub: "3 m",
        target: "21-4_2020-10-13_121439_00001-pano",
        goal_id: "cold_unit_discovery",
      },
      goal_card: {
        goal_id: "cold_unit_discovery",
        subtext: "",
        subtext_experience: "",
      },
      levels: [
        {
          tileSize: 256,
          size: 256,
          fallbackOnly: true,
        },
        {
          tileSize: 512,
          size: 512,
        },
        {
          tileSize: 512,
          size: 1024,
        },
        {
          tileSize: 512,
          size: 2048,
        },
      ],
      faceSize: 2048,
      initialViewParameters: {
        yaw: 0.34521144704361006,
        pitch: 0.2249133746271692,
        fov: 1.6763155324225463,
      },
      linkHotspots: [],
      unusedHotspots: [],
      infoHotspots: [
        {
          icon: "up",
          target: "21-4_2020-10-13_121439_00001-pano",
          yaw: 0.34522882367934393,
          pitch: 0.171621762183646,
        },
      ],
      tooltips: [
        {
          yaw: 1.1179089848669221,
          pitch: 0.4224293766211584,
          title: "Armoire de climatisation",
        },
      ],
    },
    {
      id: "21-4_2020-10-13_121439_00001-pano",
      name: "Salle serveurs",
      description:
        "Vue de l'intérieur de la salle serveurs, retour de l'expérience, face aux tuyaux",
      temperature: 29,
      guide_card: {
        thumb: "/card_thumbs/Preview_5_Rooftop_PlateHeatExchanger.jpg",
        icon: "info",
        title: "Monter sur le toit",
        sub: "Toucher pour monter",
        target: "22-5_2020-10-14_091056_00027-pano",
        goal_id: "cold_unit_discovery",
      },
      goal_card: {
        goal_id: "cold_unit_discovery",
        subtext: "",
        subtext_experience: "",
      },
      levels: [
        {
          tileSize: 256,
          size: 256,
          fallbackOnly: true,
        },
        {
          tileSize: 512,
          size: 512,
        },
        {
          tileSize: 512,
          size: 1024,
        },
        {
          tileSize: 512,
          size: 2048,
        },
      ],
      faceSize: 2048,
      initialViewParameters: {
        yaw: 0.1689813552042807,
        pitch: -0.06694550848332881,
        fov: 1.6763155324225463,
      },
      linkHotspots: [],
      unusedHotspots: [
        {
          yaw: 0.7734207423295469,
          pitch: 0.15834929527854058,
          title: "vers le cold corridor",
          target: "15-4_2020-10-13_121439_00016-pano",
        },
      ],
      infoHotspots: [
        {
          icon: "doubleup",
          target: "22-5_2020-10-14_091056_00027-pano",
          yaw: 0,
          pitch: -0.049507546819995696,
        },
      ],
      tooltips: [
        // TODO Emeric, vérifier la position des tooltips quand issue #41 sera fixée
        {
          yaw: 0.9311768355557142,
          pitch: 0.14037054811060834,
          title: "Cold corridor",
        },
        {
          yaw: -0.07480554368604686,
          pitch: -0.19642828362605158,
          title: "Flux d'eau chaude remonté : 29°C",
        },
        {
          yaw: 0.08683535946787657,
          pitch: 0.41965835372902127,
          title: "Flux d'eau froide acheminée : 20°C",
        },
      ],
    },
    {
      id: "22-5_2020-10-14_091056_00027-pano",
      description: "Premier pano sur le toit",
      name: "Toit du Data-center",
      guide_card: {
        thumb: "/card_thumbs/Preview_5_Rooftop_PlateHeatExchanger.jpg",
        icon: "marker",
        title: "Avancer jusqu'aux installations",
        sub: "4 m",
        target: "23-5_2020-10-14_091056_00023-pano",
        goal_id: "cold_unit_discovery",
      },
      goal_card: {
        goal_id: "cold_unit_discovery",
        subtext: "",
        subtext_experience: "",
      },
      levels: [
        {
          tileSize: 256,
          size: 256,
          fallbackOnly: true,
        },
        {
          tileSize: 512,
          size: 512,
        },
        {
          tileSize: 512,
          size: 1024,
        },
        {
          tileSize: 512,
          size: 2048,
        },
      ],
      faceSize: 2048,
      initialViewParameters: {
        yaw: 0.481312094326265,
        pitch: 0.3627361168023917,
        fov: 1.6763155324225463,
      },
      linkHotspots: [],
      unusedHotspots: [
        {
          yaw: -1.1203314694224638,
          pitch: 0.6847620571397925,
          title: "Retour salle serveurs",
          icon: "doubledown",
          target: "21-4_2020-10-13_121439_00001-pano",
        },
      ],
      infoHotspots: [
        {
          icon: "up",
          target: "23-5_2020-10-14_091056_00023-pano",
          yaw: 0.7508153575116729,
          pitch: 0.44707450206377786,
        },
      ],
      tooltips: [
        {
          yaw: 0.27486011137953525,
          pitch: -0.0011886846663529127,
          title: "Échangeur à plaques",
        },
        {
          yaw: 1.076489448841894,
          pitch: 0.7903192137525092,
          title: "Vanne d'alimentation",
        },
        {
          yaw: 0.9568376414583302,
          pitch: -0.04187064799221929,
          title: "Groupes froid",
        },
      ],
    },
    {
      id: "23-5_2020-10-14_091056_00023-pano",
      name: "Toit du Data-center",
      description:
        "Deuxième pano sur le toit, action cold unit, sans les textes avec tuyaux couleur",
      guide_card: {
        thumb: "/card_thumbs/Preview_5_Rooftop_PlateHeatExchanger.jpg",
        icon: "marker",
        title: "Découvrir les groupes froid",
        sub: "Toucher pour découvrir",
        type: "action",
        note:
          "ouverture de experience_id: EXPERIENCE_TYPE.COLD_UNIT_PRESENTATION",
      },
      goal_card: {
        goal_id: "cold_unit_discovery",
        subtext: "",
        subtext_experience: "",
      },
      levels: [
        {
          tileSize: 256,
          size: 256,
          fallbackOnly: true,
        },
        {
          tileSize: 512,
          size: 512,
        },
        {
          tileSize: 512,
          size: 1024,
        },
        {
          tileSize: 512,
          size: 2048,
        },
      ],
      faceSize: 2048,
      initialViewParameters: {
        yaw: 2.7633522669036363,
        pitch: 0.29469131314789365,
        fov: 1.5498549576679432,
      },
      linkHotspots: [
        {
          icon: "info",
          yaw: 1.808863321874366,
          pitch: 0.0005175761692690628,
          title: "Échangeur à plaques",
          state: "locked",
        },
        {
          icon: "info",
          yaw: 1.0234689163696817,
          pitch: 0.7002164825154154,
          title: "Vanne d'alimentation",
          state: "locked",
        },
      ],
      unusedHotspots: [
        {
          yaw: 2.7423749533941066,
          pitch: 0.45263067003411095,
          rotation: 0,
          target: "24-5_2020-10-14_091056_00019-pano",
        },
        {
          yaw: 1.4465648810382934,
          pitch: 0.3070336793481161,
          title: "retour salle serveurs",
          target: "24-5_2020-10-14_091056_00019-pano",
        },
      ],
      infoHotspots: [
        {
          type: "experience_action",
          icon: "info",
          yaw: -3.1124586180045704,
          pitch: 0.00238452612751594,
          title: "Groupes froid",
        },
      ],
      tooltips: [],
    },
    {
      id: "23-5_2020-10-14_091056_00023-pano_2",
      name: "Toit du Data-center",
      description:
        "Deuxième pano sur le toit, action vanne, sans les textes avec tuyaux couleur",
      guide_card: {
        thumb: "/card_thumbs/Preview_5_Rooftop_PlateHeatExchanger.jpg",
        icon: "info",
        title: "Dériver le circuit de refroidissement",
        sub: "Toucher pour manoeuvrer",
        type: "action",
      },
      goal_card: {
        goal_id: "valve_opening",
        subtext: "",
        subtext_experience: "",
      },
      levels: [
        {
          tileSize: 256,
          size: 256,
          fallbackOnly: true,
        },
        {
          tileSize: 512,
          size: 512,
        },
        {
          tileSize: 512,
          size: 1024,
        },
        {
          tileSize: 512,
          size: 2048,
        },
      ],
      faceSize: 2048,
      initialViewParameters: {
        yaw: 1.1584331814295616,
        pitch: 0.4392815530081755,
        fov: 1.492945254709316,
      },
      linkHotspots: [
        {
          icon: "info",
          state: "locked",
          title: "Échangeur à plaques",
          yaw: 1.8172566231313212,
          pitch: 0.005614039564584061,
        },
        {
          icon: "info",
          state: "checked",
          title: "Groupes froid",
          yaw: -3.1092199583981106,
          pitch: 0.01028278566517038,
          target: "23-5_2020-10-14_091056_00023-pano",
        },
      ],
      unusedHotspots: [
        {
          yaw: 2.7423749533941066,
          pitch: 0.45263067003411095,
          rotation: 0,
          target: "24-5_2020-10-14_091056_00019-pano",
        },
        {
          yaw: 1.4465648810382934,
          pitch: 0.3070336793481161,
          title: "retour salle serveurs",
          target: "24-5_2020-10-14_091056_00019-pano",
        },
      ],
      infoHotspots: [
        {
          yaw: 1.0203527609420995,
          pitch: 0.6850622917749494,
          type: "experience_action",
          icon: "info",
          title: "Vanne",
        },
      ],
      tooltips: [],
    },
    {
      id: "23-5_2020-10-14_091056_00023-pano_3",
      name: "Toit du Data-center",
      description:
        "Deuxième pano sur le toit, action PHE, sans les textes avec tuyaux couleur",
      guide_card: {
        thumb: "/card_thumbs/Preview_5_Rooftop_PlateHeatExchanger.jpg",
        icon: "info",
        title: "Découvrir l'échangeur à plaques",
        sub: "Toucher pour découvrir",
        type: "action",
        note: "ouverture experience_id: EXPERIENCE_TYPE.PLATE_HEAT_EXCHANGER",
      },
      goal_card: {
        goal_id: "plate_heat_exchanger_discovery",
        subtext: "",
        subtext_experience: "",
      },
      levels: [
        {
          tileSize: 256,
          size: 256,
          fallbackOnly: true,
        },
        {
          tileSize: 512,
          size: 512,
        },
        {
          tileSize: 512,
          size: 1024,
        },
        {
          tileSize: 512,
          size: 2048,
        },
      ],
      faceSize: 2048,
      initialViewParameters: {
        yaw: 1.6769642378295897,
        pitch: 0.34876379974547334,
        fov: 1.492945254709316,
      },
      linkHotspots: [
        {
          icon: "info",
          state: "checked",
          yaw: -3.1092199583981106,
          pitch: 0.01028278566517038,
          title: "Groupes froid",
          target: "23-5_2020-10-14_091056_00023-pano",
        },
        {
          icon: "info",
          yaw: 1.0234689163696817,
          pitch: 0.7002164825154154,
          title: "Vanne d'alimentation",
          state: "checked",
          target: "23-5_2020-10-14_091056_00023-pano_2",
        },
      ],
      unusedHotspots: [
        {
          yaw: 2.7423749533941066,
          pitch: 0.45263067003411095,
          rotation: 0,
          target: "24-5_2020-10-14_091056_00019-pano",
        },
        {
          yaw: 1.4465648810382934,
          pitch: 0.3070336793481161,
          title: "retour salle serveurs",
          target: "24-5_2020-10-14_091056_00019-pano",
        },
      ],
      infoHotspots: [
        {
          type: "experience_action",
          icon: "info",
          yaw: 1.8172566231313212,
          pitch: 0.005614039564584061,
          title: "Échangeur à plaques",
        },
      ],
      tooltips: [
        // {
        //   yaw: 1.0203527609420995,
        //   pitch: 0.6850622917749494,
        //   title: "Vanne d'alimentation",
        // },
      ],
    },
    {
      id: "23-5_2020-10-14_091056_00023-pano_texts",
      name: "Toit du Data-center",
      description: "Deuxième pano sur le toit, retour PHE, AVEC les textes",
      guide_card: {
        thumb: "/card_thumbs/Preview_6_SkyView_MRS2.jpg",
        icon: "marker",
        title: "Marseille vue du ciel",
        target: "25-6_100_0014_f",
        sub: "Toucher pour continuer",
        note: "ouverture experience_id: EXPERIENCE_TYPE.",
      },
      goal_card: {
        goal_id: "river_cooling_water",
        subtext: "",
        subtext_experience: "",
      },
      levels: [
        {
          tileSize: 256,
          size: 256,
          fallbackOnly: true,
        },
        {
          tileSize: 512,
          size: 512,
        },
        {
          tileSize: 512,
          size: 1024,
        },
        {
          tileSize: 512,
          size: 2048,
        },
      ],
      faceSize: 2048,
      initialViewParameters: {
        yaw: 2.7922213845048205,
        pitch: 0.039129356178126073,
        fov: 1.5853379997699353,
      },
      linkHotspots: [
        {
          icon: "info",
          state: "checked",
          title: "Échangeur à plaques",
          yaw: 1.8172566231313212,
          pitch: 0.005614039564584061,
          target: "23-5_2020-10-14_091056_00023-pano_3",
        },
        {
          icon: "info",
          state: "checked",
          title: "Groupes froid",
          yaw: -3.1092199583981106,
          pitch: -0.01028278566517038,
          target: "23-5_2020-10-14_091056_00023-pano",
        },
        {
          yaw: 1.0203527609420995,
          pitch: 0.6850622917749494,
          state: "checked",
          icon: "info",
          title: "Vanne d'alimentation",
          target: "23-5_2020-10-14_091056_00023-pano_2",
        },
      ],
      unusedHotspots: [
        {
          yaw: 2.7423749533941066,
          pitch: 0.45263067003411095,
          rotation: 0,
          target: "24-5_2020-10-14_091056_00019-pano",
        },
        {
          yaw: 1.4465648810382934,
          pitch: 0.3070336793481161,
          title: "retour salle serveurs",
          target: "24-5_2020-10-14_091056_00019-pano",
        },
      ],
      infoHotspots: [
        {
          yaw: 2.7748504038020414,
          pitch: -0.27258851827916786,
          icon: "doubleup",
          target: "25-6_100_0014_f",
        },
      ],
      tooltips: [],
    },
    {
      id: "25-6_100_0014_f",
      name: "Marseille vue du ciel",
      description: "Vue verticale toit MRS2",
      guide_card: {
        thumb: "/card_thumbs/Preview_6_SkyView_MRS2.jpg",
        icon: "marker",
        title: "Suivre l'eau jusqu'à la Galerie à la Mer",
        sub: "Toucher pour avancer",
        target: "28-6_100_0011_f",
        goal_id: "river_cooling_water",
      },
      goal_card: {
        goal_id: "river_cooling_water",
        subtext: "",
        subtext_experience: "",
      },
      levels: [
        {
          tileSize: 256,
          size: 256,
          fallbackOnly: true,
        },
        {
          tileSize: 512,
          size: 512,
        },
        {
          tileSize: 512,
          size: 1024,
        },
        {
          tileSize: 512,
          size: 2048,
        },
        {
          tileSize: 512,
          size: 4096,
        },
      ],
      faceSize: 4072.5,
      initialViewParameters: {
        yaw: -2.739736913205947,
        pitch: 0.2516061004909833,
        fov: 1.3023644552110467,
      },
      linkHotspots: [
        {
          yaw: -0.08579079907395482,
          pitch: 0.6992082894041438,
          title: "Rootftop",
          state: "checked",
          icon: "info",
          target: "23-5_2020-10-14_091056_00023-pano_texts",
        },
      ],
      unusedHotspots: [
        {
          yaw: 0.27176700411762056,
          pitch: -0.004765162207611695,
          rotation: 9.42477796076938,
          target: "26-6_100_0020_f",
        },
        {
          yaw: -2.923448187192948,
          pitch: 0.23331275897914772,
          rotation: 3.141592653589793,
          target: "28-6_100_0011_f",
        },
        {
          icon: "dot",
          status: "locked",
          yaw: -2.7943369581516073,
          pitch: 0.04161153009745888,
          title: "station de pompage",
        },
      ],
      infoHotspots: [
        {
          target: "28-6_100_0011_f",
          icon: "up",
          yaw: -2.888291613328084,
          pitch: -0.013633746353031384,
          title: "galerie à la mer",
        },
      ],
      tooltips: [
        {
          yaw: -2.6823925299760703,
          pitch: 0.565584535027222,
          title: "Data-center MRS2",
        },
        {
          yaw: -2.8244911276382325,
          pitch: 0.19252799971048695,
          title: "Eau froide du river cooling acheminée à 15 °C",
        },
      ],
    },
    {
      id: "28-6_100_0011_f",
      name: "Marseille vue du ciel",
      description: "Vue verticale toit MRS2",
      guide_card: {
        thumb: "/card_thumbs/Preview_6_SkyView_MRS2.jpg",
        icon: "marker",
        title: "Suivre l'eau jusqu'à la Galerie à la Mer",
        sub: "Toucher pour avancer",
        target: "29-7_2020-10-13_154432_00037-pano",
        goal_id: "river_cooling_water",
      },
      goal_card: {
        goal_id: "river_cooling_water",
        subtext: "",
        subtext_experience: "",
      },
      levels: [
        {
          tileSize: 256,
          size: 256,
          fallbackOnly: true,
        },
        {
          tileSize: 512,
          size: 512,
        },
        {
          tileSize: 512,
          size: 1024,
        },
        {
          tileSize: 512,
          size: 2048,
        },
        {
          tileSize: 512,
          size: 4096,
        },
      ],
      faceSize: 4092.5,
      initialViewParameters: {
        yaw: -0.5037380929637472,
        pitch: 0.21968533121209965,
        fov: 1.492945254709316,
      },
      linkHotspots: [
        {
          icon: "gear",
          state: "checked",
          yaw: 2.5357161048467205,
          pitch: 0.09450244746306069,
          target: "23-5_2020-10-14_091056_00023-pano_texts",
        },
      ],
      unusedHotspots: [],
      infoHotspots: [
        {
          yaw: -0.7649232725928794,
          pitch: -0.0535046320840582,
          title: "galerie à la mer",
          icon: "marker",
          target: "29-7_2020-10-13_154432_00037-pano",
        },
      ],
      tooltips: [
        {
          yaw: 2.4751809698655256,
          pitch: 0.2134987603755718,
          title: "Data-center MRS2",
        },
        {
          yaw: -0.5100551011008001,
          pitch: 0.011556654190794546,
          title: "Station de pompage",
        },
        {
          yaw: -0.4910399357070112,
          pitch: 0.2764816144680964,
          title: "Eau froide du river cooling acheminée à 15 °C",
        },
      ],
    },
    {
      id: "29-7_2020-10-13_154432_00037-pano",
      name: "Galerie à la mer",
      description: "Galerie à la mer, amont, au fond",
      guide_card: {
        thumb: "/card_thumbs/Preview_7_SeaGalery_Pool.jpg",
        icon: "marker",
        title: "Redescendre la galerie vers le bassin",
        sub: "200 m",
        target: "31-7_2020-10-13_154432_00051-pano",
        goal_id: "cold_water_discovery",
      },
      goal_card: {
        goal_id: "cold_water_discovery",
        subtext: "",
        subtext_experience: "",
      },
      place_intro: {
        title: "Arrivée dans la Galerie à la Mer",
        text:
          "Vous êtes maintenant dans la Galerie de la Mer. Il s’agit d’une galerie souterraine construite entre 1890 et 1905 pour évacuer les eaux de ruissellement de la mine de Gardanne. Elle récupère également les eaux d’infiltration et s’écoule par gravimétrie jusqu’au Grand Port Maritime de Marseille, effectuant ainsi un parcours de 15 kilomètres.",
      },
      levels: [
        {
          tileSize: 256,
          size: 256,
          fallbackOnly: true,
        },
        {
          tileSize: 512,
          size: 512,
        },
        {
          tileSize: 512,
          size: 1024,
        },
        {
          tileSize: 512,
          size: 2048,
        },
      ],
      faceSize: 2048,
      initialViewParameters: {
        yaw: -2.7828140597755002,
        pitch: 0.022121753648892906,
        fov: 1.6602887897041279,
      },
      linkHotspots: [],
      unusedHotspots: [],
      infoHotspots: [
        {
          icon: "up",
          yaw: 3.131274090110174,
          pitch: 0.05100574324421103,
          title: "Vers le bassin",
          target: "31-7_2020-10-13_154432_00051-pano",
        },
      ],
      tooltips: [
        {
          yaw: -2.521031418418076,
          pitch: 0.08175863537948125,
          title: "Galerie à la mer, chemin du littoral Marseille",
        },
        {
          yaw: -0.011609412700394728,
          pitch: 0.06700443928469113,
          title: "Vers la mine de Gardanne - 14 km",
        },
      ],
    },
    {
      id: "31-7_2020-10-13_154432_00051-pano",
      name: "Galerie à la mer",
      description: "Galerie à la mer, amont du bassin dans la galerie, step 2",
      guide_card: {
        thumb: "/card_thumbs/Preview_7_SeaGalery_Pool.jpg",
        icon: "marker",
        title: "Avancer vers le bassin",
        sub: "Toucher pour avancer",
        target: "32-7_2020-10-13_154432_00017-pano",
        goal_id: "cold_water_discovery",
      },
      goal_card: {
        goal_id: "cold_water_discovery",
        subtext: "",
        subtext_experience: "",
      },
      levels: [
        {
          tileSize: 256,
          size: 256,
          fallbackOnly: true,
        },
        {
          tileSize: 512,
          size: 512,
        },
        {
          tileSize: 512,
          size: 1024,
        },
        {
          tileSize: 512,
          size: 2048,
        },
      ],
      faceSize: 2048,
      initialViewParameters: {
        yaw: -0.10241822204011086,
        pitch: 0.3148701411680985,
        fov: 1.492945254709316,
      },
      linkHotspots: [],
      unusedHotspots: [
        {
          yaw: -0.13729482018706207,
          pitch: 0.1988314558177855,
          rotation: 3.141592653589793,
          target: "32-7_2020-10-13_154432_00017-pano",
        },
        {
          yaw: 0.17158109240661545,
          pitch: 0.0442581683097103,
          rotation: 6.283185307179586,
          target: "35-7_2020-10-13_154432_00010-pano",
        },
        {
          yaw: -3.021748453652325,
          pitch: 0.04464157834868132,
          rotation: 0,
          target: "30-7_2020-10-13_154432_00035-pano",
        },
      ],
      infoHotspots: [
        {
          icon: "marker",
          yaw: -0.12570860158412422,
          pitch: 0.19691047486184843,
          target: "32-7_2020-10-13_154432_00017-pano",
        },
      ],
      tooltips: [
        {
          yaw: -0.5089382020611417,
          pitch: 0.463048081518874,
          title: "Eau naturellement froide du River Cooling à 15°C",
        },
      ],
    },
    {
      id: "32-7_2020-10-13_154432_00017-pano",
      name: "Galerie à la mer",
      description: "Galerie à la mer, amont du bassin, juste à côté",
      guide_card: {
        thumb: "/card_thumbs/Preview_7_SeaGalery_Pool.jpg",
        icon: "info",
        title: "Écouter l'écoulement de l'eau du bassin",
        sub: "Toucher pour écouter",
        type: "action",
        goal_id: "cold_water_discovery",
      },
      goal_card: {
        goal_id: "cold_water_discovery",
        subtext: "",
        subtext_experience: "",
      },
      levels: [
        {
          tileSize: 256,
          size: 256,
          fallbackOnly: true,
        },
        {
          tileSize: 512,
          size: 512,
        },
        {
          tileSize: 512,
          size: 1024,
        },
        {
          tileSize: 512,
          size: 2048,
        },
      ],
      faceSize: 2048,
      initialViewParameters: {
        yaw: -0.08285519086390991,
        pitch: 0.31640609307624423,
        fov: 1.492945254709316,
      },
      linkHotspots: [],
      unusedHotspots: [
        {
          yaw: 2.60541457515631,
          pitch: 0.013089365458151647,
          rotation: 0,
          target: "30-7_2020-10-13_154432_00035-pano",
        },
      ],
      infoHotspots: [
        {
          type: "experience_action",
          yaw: -0.46819628974962413,
          pitch: 0.5326975216382355,
          icon: "info",
        },
      ],
      tooltips: [
        {
          yaw: -0.33475839190297485,
          pitch: 0.7391832767628213,
          title: "Bassin de décantation à 15 °C",
        },
      ],
    },
    {
      id: "33-7_2020-10-13_154432_00017-pano_2",
      name: "Galerie à la mer",
      description:
        "Galerie à la mer, amont du bassin, retour de vidéo sur le bassin",
      guide_card: {
        thumb: "/card_thumbs/Preview_8_PupmingStation_Installation.jpg",
        icon: "marker",
        title: "Rejoindre la station de pompage",
        sub: "Toucher pour rejoindre",
        target: "36-8_2020-10-13_164209_00004-pano",
        goal_id: "water_pumping",
      },
      goal_card: {
        goal_id: "water_pumping",
        subtext: "",
        subtext_experience: "",
      },

      levels: [
        {
          tileSize: 256,
          size: 256,
          fallbackOnly: true,
        },
        {
          tileSize: 512,
          size: 512,
        },
        {
          tileSize: 512,
          size: 1024,
        },
        {
          tileSize: 512,
          size: 2048,
        },
      ],
      faceSize: 2048,
      initialViewParameters: {
        yaw: -0.08285519086390991,
        pitch: 0.31640609307624423,
        fov: 1.492945254709316,
      },
      linkHotspots: [
        {
          icon: "info",
          yaw: -0.46819628974962413,
          pitch: 0.5326975216382355,
          state: "checked",
          target: "32-7_2020-10-13_154432_00017-pano",
        },
      ],
      unusedHotspots: [
        {
          yaw: 2.595923754534663,
          pitch: 0.005184834485138623,
          rotation: 0,
          target: "30-7_2020-10-13_154432_00035-pano",
        },
      ],
      infoHotspots: [
        {
          icon: "up",
          yaw: -0.1346306624799425,
          pitch: 0.10486921025105644,
          title: "vers station de pompage",
          target: "36-8_2020-10-13_164209_00004-pano",
        },
      ],
    },
    {
      id: "36-8_2020-10-13_164209_00004-pano",
      name: "Station de pompage",
      description: "Station de pompage, au fond",
      guide_card: {
        thumb: "/card_thumbs/Preview_8_PupmingStation_Installation.jpg",
        icon: "marker",
        title: "Avancer pour découvrir l'expérience",
        sub: "Toucher pour avancer",
        type: "action",
        goal_id: "water_pumping",
      },
      goal_card: {
        goal_id: "water_pumping",
        subtext: "",
        subtext_experience: "",
      },
      place_intro: {
        title: "Station de pompage",
        text:
          "Vous êtes maintenant dans la station de pompage.<br><br>L’eau de la Galerie de la Mer est pompée puis filtrée dans ces équipements.<br><br>L’eau est envoyée via ce réseau jusqu’au data center. Cette eau revient ensuite par le circuit de retour et rejoint de nouveau la Galerie de la Mer pour reprendre son parcours habituel.",
      },
      levels: [
        {
          tileSize: 256,
          size: 256,
          fallbackOnly: true,
        },
        {
          tileSize: 512,
          size: 512,
        },
        {
          tileSize: 512,
          size: 1024,
        },
        {
          tileSize: 512,
          size: 2048,
        },
      ],
      faceSize: 2048,
      initialViewParameters: {
        yaw: 0.458170261442131,
        pitch: 0.09983687402891483,
        fov: 1.492945254709316,
      },
      linkHotspots: [
        {
          yaw: 0.5628609689784341,
          pitch: -0.12541183840582093,
          title: "escaliers interdits",
          icon: "doubleup",
          state: "locked",
        },
      ],
      unusedHotspots: [
        {
          yaw: 0.7797276070622647,
          pitch: 0.1905743927549608,
          rotation: 3.141592653589793,
          target: "37-8_2020-10-13_164209_00011-pano",
        },
      ],
      infoHotspots: [
        {
          icon: "info",
          yaw: 0.46957399479056683,
          pitch: 0.14327901027394674,
          title: "expérience station de pompage",
          type: "experience_action",
        },
      ],
      tooltips: [
        {
          yaw: 1.04,
          pitch: -0.28283608850591335,
          title: "Vers le data-center",
        },
        // {
        //   "yaw": 0.9270676058337699,
        //   "pitch": -0.21453261565844173,
        //   "title": "Eau réchauffée de retour à 29°C",
        // },
        {
          yaw: 0.002888213828285302,
          pitch: -0.08495968123837727,
          title: "Bassin",
        },
      ],
    },
    {
      id: "37-8_2020-10-13_164209_00011-pano",
      name: "Station de pompage",
      description: "Station de pompage entre les pompes",
      guide_card: {
        thumb: "/card_thumbs/Preview_8_PupmingStation_Installation.jpg",
        icon: "doubleup",
        title: "Marseille vue du ciel",
        sub: "Toucher pour continuer",
        target: "41-6_100_0001_f",
        goal_id: "river_cooling_water_loop",
      },
      goal_card: {
        goal_id: "river_cooling_water_loop",
        subtext: "",
        subtext_experience: "",
      },
      levels: [
        {
          tileSize: 256,
          size: 256,
          fallbackOnly: true,
        },
        {
          tileSize: 512,
          size: 512,
        },
        {
          tileSize: 512,
          size: 1024,
        },
        {
          tileSize: 512,
          size: 2048,
        },
      ],
      faceSize: 2048,
      initialViewParameters: {
        yaw: -1.388573286494271,
        pitch: 0.2657571426611849,
        fov: 1.492945254709316,
      },
      linkHotspots: [],
      unusedHotspots: [
        {
          yaw: -2.6198192972841348,
          pitch: 0.052141531577166944,
          rotation: 3.141592653589793,
          target: "36-8_2020-10-13_164209_00004-pano",
        },
        {
          yaw: 0.0691368491324802,
          pitch: 0.104130456343686,
          rotation: 0,
          target: "38-8_2020-10-13_164209_00014-pano",
        },
      ],
      infoHotspots: [
        // TODO Emeric edit position
        {
          yaw: -1.4296417004609054,
          pitch: -0.15870916191073192,
          target: "41-6_100_0001_f",
          icon: "doubleup",
        },
      ],
      tooltips: [
        {
          yaw: -1.4451686857437878,
          pitch: 0.2310549219348239,
          title: "Bassin",
        },
        {
          yaw: -2.990179437799828,
          pitch: 0.02790154858060845,
          title: "Vers la galerie à la mer",
        },
        {
          yaw: -2.0029849462113383,
          pitch: 0.4622254775985528,
          title: "Pompe de filtrage de l'eau",
        },
      ],
    },
    {
      id: "41-6_100_0001_f",
      name: "Marseille vue du ciel",
      description:
        "Vue arérienne verticale station de pompage vers MRS2 avec flux d'ean AR",
      guide_card: {
        icon: "check",
        title: "Bravo, vous avez terminé l'expérience",
        sub: "Toucher pour continuer",
        target: "CLOSING",
      },
      levels: [
        {
          tileSize: 256,
          size: 256,
          fallbackOnly: true,
        },
        {
          tileSize: 512,
          size: 512,
        },
        {
          tileSize: 512,
          size: 1024,
        },
        {
          tileSize: 512,
          size: 2048,
        },
        {
          tileSize: 512,
          size: 4096,
        },
      ],
      faceSize: 4069.5,
      initialViewParameters: {
        yaw: -0.908394652643743,
        pitch: 0.5633160528900767,
        fov: 1.5707963267948966
      },
      linkHotspots: [],
      infoHotspots: [
        {
          yaw: -0.7472677442390445,
          pitch: 0.19132078744197933,
          target: "CLOSING",
        },
      ],
      tooltips: [
        {
          yaw: -0.3241044331806666,
          pitch: 0.007506013359403596,
          title: "Data-center MRS2",
        },
        {
          yaw: -0.28869691718579915,
          pitch: 0.9313343359624362,
          title: "Station de pompage",
        },
        {
          yaw: -1.5447557438180173,
          pitch: 0.5464349600566969,
          title: "Conformément aux autorisations préfectorales, le Rivercooling garantie un retour de l’eau à la Galerie à la Mer, sans prélèvement, tout en respectant les limites de température fixées par Arrêté Préfectoral N°198-2017 du 27 février 2019.",
        },
        {
          yaw: 2.5417824676473373,
          pitch: 0.6976229198601889,
          title: "Projet raccordement aux futurs quartiers Euro-méditerranéen dans l’objectif d’alimenter en chauffage et eau chaude jusqu’à 500 000 m2 de surface logement ou de bureau, soit l’équivalent de 2 300 maisons.",
        }
      ],
    },
  ],
  name: "Project Title",
  settings: {
    mouseViewMode: "drag",
    autorotateEnabled: true,
    fullscreenButton: false,
    viewControlButtons: false,
  },
};

export default APP_DATA;
