import { ButtonPrimary } from "components/button";
import IconSwitch from "components/iconSwitch";
import React from "react";
import { useDispatch } from "react-redux";
import styles from "./Navbar.module.scss";
import { motion } from "framer-motion";

function NavbarDropdown({ goals }) {
  const dispatch = useDispatch();
  function navigateToScene(target) {
    dispatch({
      type: "GO_TO_SCENE_BY_ID",
      sceneId: target,
    });
  }
  console.log(goals);
  return (
    <motion.div
      initial={{ y: -30, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: 50, opacity: 0 }}
      layout
      className={styles.dropdown}
    >
      <div className={styles.dropdownScrollArea}>
        {goals.map((g) => {
          return (
            <div
              key={g.id}
              className={`${styles.dropdownItem} ${
                g.status === "locked" && styles.locked
              }`}
            >
              <div className={styles.dropdownThumb}>
                <img
                  src={g.thumb || "/img/default-thumb.jpg"}
                  alt="miniature objectif"
                />
                <div className={styles.dropdowIcon}>
                  <IconSwitch icon={g.status === "locked" ? "lock" : g.icon} />
                </div>
              </div>
              <div className={styles.dropdownTitle}>
                <span>{g.title}</span>
                {g.status !== "locked" && (
                  <ButtonPrimary
                    small
                    onClick={() => navigateToScene(g.target)}
                  >
                    Accéder
                  </ButtonPrimary>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </motion.div>
  );
}

export default NavbarDropdown;
