import React from "react";
import styles from "../coldCorridor/ColdCorridor.module.scss";
import { Tab, TabPanel } from "@reach/tabs";
import TabsContainer from "components/tabs";
import Drawer from "components/drawer";
import { useSelector, useDispatch } from "react-redux";
import NextTab from "../coldCorridor/NextTab";

const photos = [
  "/images/pompe_filtrage.jpg",
  "/images/arrivee-depart-eau.jpg",
];

const WaterPumping = () => {
  const [tabIndex, setTabIndex] = React.useState(0);
  const currentGoal = useSelector((state) => state.appReducer.currentGoal);
  const dispatch = useDispatch();
  const tabsRef = React.useRef();

  // Scroll to center current tab on tab change
  React.useEffect(() => {
    if (!tabsRef.current) return;
    const tabSelected = tabsRef.current.querySelectorAll(
      '[aria-selected="true"]'
    )[0];
    if (tabSelected) tabSelected.scrollIntoView(false);
  }, [tabIndex]);

  function handleClose() {
    dispatch({
      type: "UNLOCK_GOAL",
    });
    dispatch({
      type: "GO_TO_SCENE_BY_ID",
      sceneId: currentGoal.unlockTarget,
    });
  }

  return (
    <Drawer
      isOpen={true}
      onLeftClick={handleClose}
      name="Comment l’eau est pompée et filtrée ?"
    >
      <div className={styles.viewerFull}>
        <div className={styles.viewerImage}>
          <img className={styles.tabImage} src={photos[tabIndex]} />
        </div>
      </div>
      <div ref={tabsRef} className={styles.tabs}>
        <TabsContainer
          tabIndex={tabIndex}
          setTabIndex={setTabIndex}
          tabs={() => (
            <>
              <Tab>Pompe de filtrage</Tab>
              <Tab>Acheminement vers le data-center</Tab>
            </>
          )}
        >
          <TabPanel>
            <div className={styles.tabContent}>L’eau de la Galerie de la Mer est pompée puis filtrée dans ces équipements.</div>
          </TabPanel>
          <TabPanel>
            <div className={styles.tabContent}>L’eau est envoyée via ce réseau jusqu’au data center. Cette eau revient ensuite par le circuit de retour et rejoint de nouveau la Galerie de la Mer pour reprendre son parcours habituel.</div>
          </TabPanel>
        </TabsContainer>
      </div>
      <NextTab
        isLast={tabIndex === 1}
        handleNext={() => {
          if (tabIndex < 1) {
            setTabIndex(tabIndex + 1);
          } else {
            handleClose();
          }
        }}
      />
    </Drawer>
  );
};

export default WaterPumping;
