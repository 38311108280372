import IconSwitch from "components/iconSwitch";
import React from "react";
import styles from "./Hotspot.module.scss";
import { motion } from "framer-motion";

export function HotspotInfo({ hotspot, handleSwitchScene, ...rest }) {
  return (
    <HotspotMain
      {...rest}
      onClick={handleSwitchScene}
      hotspot={hotspot}
    ></HotspotMain>
  );
}

export function HotspotLink({ hotspot, handleSwitchScene, scene }) {
  const locked = hotspot.state === "locked";
  const checked = hotspot.state === "checked";
  return (
    <div
      onClick={() => {
        !locked && handleSwitchScene();
      }}
      className={`${styles.linkHotspot} ${locked && styles.lockedHotspot}`}
    >
      {locked && (
        <div className={styles.lockedIcon}>
          <IconSwitch icon="lock" />
        </div>
      )}
      {checked && (
        <div className={styles.checkedIcon}>
          <IconSwitch icon="checked" />
        </div>
      )}
      <IconSwitch icon={hotspot.icon} />
    </div>
  );
}

export function HotspotMain({ hotspot, ...rest }) {
  return (
    <motion.button
      animate={{
        boxShadow: [
          "0 0 0 0 rgba(125, 184, 14, 0.5), 0 0 0 1rem rgba(125, 184, 14, 0.1)",
          "0 0 0 1rem rgba(125, 184, 14, .75), 0 0 0 3rem rgba(125, 184, 14, .25)",
          "0 0 0 0 rgba(125, 184, 14, 0.5), 0 0 0 1rem rgba(125, 184, 14, .1)",
        ],
        scale: [0.95, 1, 0.95],
      }}
      transition={{
        duration: 1.2,
        type: "tween",
        ease: "easeOut",
        alternate: true,
        loop: Infinity,
      }}
      {...rest}
      className={styles.mainHotspot}
    >
      <IconSwitch icon={hotspot.icon} />
    </motion.button>
  );
}

export function HotspotCard({ icon }) {
  return <button className={styles.mainHotspot}>{icon}</button>;
}

export function Tooltip({ title }) {
  if (title.length > 80) {
    return (
      <div className={styles.tooltipLarge}>
        <div className={styles.tooltipRound}>
          <span></span>
        </div>
        {title && <div className={styles.tooltipContent}>{title}</div>}
      </div>
    );
  }
  return (
    <div className={styles.tooltip}>
      <div className={styles.tooltipRound}>
        <span></span>
      </div>
      {title && <div className={styles.tooltipContent}>{title}</div>}
      {title && (
        <>
          <div className={styles.tooltipLine}></div>
          <div className={styles.tooltipTip}></div>
        </>
      )}
    </div>
  );
}
