import { ButtonOutline } from "components/button";
import button from "components/button/Button.module.scss";
import React from "react";
import styles from "./Unlock.module.scss";
import openValve from "assets/img/valve_open.jpeg";
import closedValve from "assets/img/valve_closed.jpeg";
import { useDispatch, useSelector } from "react-redux";
import GearUnlock from "components/hotspots/GearUnlock";
import IconClose from "assets/svg/IconClose";
import IconCheck from "assets/svg/IconCheck";

const ValveClosing = () => {
  const [unlock, setUnlock] = React.useState(false);
  const [currentText, setCurrentText] = React.useState(
    "Cliquer sur la vanne pour l'ouvrir"
  );
  const [finish, setFinish] = React.useState(false);
  const timeoutRef = React.useRef();
  const dispatch = useDispatch();
  const currentGoal = useSelector((state) => state.appReducer.currentGoal);

  React.useEffect(() => {
    if (!unlock) return;
    setCurrentText("Vanne en cours d'ouverture…");
    timeoutRef.current = window.setTimeout(() => {
      setFinish(true);
    }, 2000);
  }, [unlock]);

  function handleUnlockGoal() {
    const unlockTarget = currentGoal.unlockTarget;
    dispatch({
      type: "UNLOCK_GOAL",
    });
    dispatch({
      type: "GO_TO_SCENE_BY_ID",
      sceneId: unlockTarget,
    });
  }

  function handleClose() {
    dispatch({
      type: "END_EXPERIENCE",
    });
  }

  return (
    <div className={styles.fullHeight}>
      <div
        className={styles.scanId}
        style={{
          backgroundImage: `url(${finish ? openValve : closedValve})`,
        }}
      >
        {/* Preload second image */}
        <img
          style={{ display: "none" }}
          src={finish ? openValve : closedValve}
          alt=""
        />
        <div className={styles.overlay}></div>
        <div className={styles.center}>
          <div className={styles.contentCard}>
            <p>Passer de la technologie classique des groupes froids à la</p>
            <p>
              <strong>technologie innovante du River Cooling</strong>
            </p>
          </div>
          <GearUnlock active={unlock} handleSetActive={() => setUnlock(true)} />
          <div className={styles.cardBottom}>
            {finish ? (
              <div className="text-center">
                <ButtonOutline onClick={handleUnlockGoal}>
                  Continuer
                </ButtonOutline>
              </div>
            ) : (
              <div className={styles.text}>{currentText}</div>
            )}
            {finish ? (
              <button
                style={{
                  backgroundColor: "var(--green)",
                  borderColor: "var(--green)",
                }}
                className={button.modalButton}
                onClick={handleUnlockGoal}
              >
                <IconCheck />
              </button>
            ) : (
              <button className={button.modalButton} onClick={handleClose}>
                <IconClose />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ValveClosing;
