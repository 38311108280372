import React from "react";
import styles from "./Button.module.scss";

export const ButtonPrimary = ({ children, full, small, onboard, ...rest }) => {
  return (
    <button
      {...rest}
      className={`${styles.buttonPrimary} ${full && styles.full} ${
        onboard && styles.onboard
      } ${small && styles.small}`}
    >
      {children}
    </button>
  );
};

export const ButtonText = ({ children, ...rest }) => {
  return (
    <button {...rest} className={styles.buttonText}>
      {children}
    </button>
  );
};

export const ButtonOutline = ({ children, full, ...rest }) => {
  return (
    <button
      {...rest}
      className={`${styles.buttonOutline} ${full && styles.full}`}
    >
      {children}
    </button>
  );
};
