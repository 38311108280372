import React from "react";

function IconFinger() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M9.836 9.377a5.866 5.866 0 013.405-.474 6.654 6.654 0 015.105 3.975.697.697 0 01-.093.788.718.718 0 01-1.18-.145 5.299 5.299 0 00-3.989-3.203 4.615 4.615 0 00-4.576 2.088c-1.058 1.801-.615 5.09 2.774 8.364a.713.713 0 01.166.785.72.72 0 01-.666.445.741.741 0 01-.5-.2c-3.933-3.818-4.348-7.836-3.004-10.124a5.887 5.887 0 012.558-2.299zM9.47 7.163a8.445 8.445 0 014.4-.405 8.67 8.67 0 016.834 5.719 3.103 3.103 0 01-.2 2.388 2.855 2.855 0 01-1.616 1.43 3.534 3.534 0 01-4.404-1.988c-.63-1.415-1.301-1.758-1.93-1.901a1.943 1.943 0 00-2.016 1.086 4.288 4.288 0 001.215 3.861 8.152 8.152 0 004.19 2.388.722.722 0 01.672.758.719.719 0 01-.758.672h-.1a9.518 9.518 0 01-5.09-2.86 5.485 5.485 0 01-1.431-5.319 3.375 3.375 0 013.732-1.973 4.099 4.099 0 012.86 2.716 2.162 2.162 0 002.617 1.23c.347-.132.629-.393.786-.73.205-.402.246-.868.115-1.3-.058-.186-.13-.358-.2-.544a7.27 7.27 0 00-5.506-4.218 6.957 6.957 0 00-6.62 2.403 7.149 7.149 0 00-.757 6.734.735.735 0 01-.473.901.71.71 0 01-.543-.06.708.708 0 01-.343-.426 8.577 8.577 0 01.972-7.993 8.466 8.466 0 013.594-2.57zm2.75 6.537a.73.73 0 01.557.083c.168.1.289.263.336.453.544 2.116 2.23 3.203 5.09 3.203.19 0 .372.075.506.21.134.133.21.315.21.505a.726.726 0 01-.219.531.72.72 0 01-.54.198c-4.446 0-5.976-2.345-6.477-4.29a.738.738 0 01.537-.893zm-2.7-9.01a10.64 10.64 0 014.665-.234 11.31 11.31 0 015.204 2.273.72.72 0 01.122 1.009.719.719 0 01-1.009.121 9.996 9.996 0 00-4.517-2.002 9.429 9.429 0 00-7.707 2.045 8.205 8.205 0 00-1.016 1.1.715.715 0 01-.572.287.778.778 0 01-.429-.143.735.735 0 01-.143-.958c.387-.47.818-.9 1.287-1.287A10.64 10.64 0 019.52 4.69zM6.506 3.255a13.821 13.821 0 019.58-.715.696.696 0 01.501.886.685.685 0 01-.595.571.687.687 0 01-.292-.027 12.27 12.27 0 00-8.579.657.69.69 0 01-.286 0 .71.71 0 01-.7-.429.729.729 0 01.371-.943z"
      ></path>
    </svg>
  );
}

export default IconFinger;
