import React from "react";

function IconFullscreen() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill="currentColor"
          d="M8 3a1 1 0 010 2H5v3a1 1 0 01-2 0V4a1 1 0 011-1h4zm12 0a1 1 0 011 1v4a1 1 0 01-2 0V5h-3a1 1 0 010-2h4zM4 15a1 1 0 011 1v3h3a1 1 0 010 2H4a1 1 0 01-1-1v-4a1 1 0 011-1zm12 6a1 1 0 010-2h3v-3a1 1 0 012 0v4a1 1 0 01-.883.993L20 21h-4z"
        ></path>
      </g>
    </svg>
  );
}

export default IconFullscreen;
