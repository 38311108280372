import { Tabs, TabList, Tab, TabPanels } from "@reach/tabs";
import "@reach/tabs/styles.css";
import "./Tabs.module.scss";
import React from "react";

function TabsContainer({ tabs, tabIndex, setTabIndex, children, inverse }) {
  return (
    <Tabs index={tabIndex} onChange={(i) => setTabIndex(i)}>
      {inverse ? (
        <>
          <TabPanels>{children}</TabPanels>
          <TabList className="tabsFixed">{tabs()}</TabList>
        </>
      ) : (
        <>
          <TabList>{tabs()}</TabList>
          <TabPanels>{children}</TabPanels>
        </>
      )}
    </Tabs>
  );
}

export default TabsContainer;
