import React from "react";

function IconDoubleUp() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12.894 13.263l6.962 3.48a.25.25 0 01-.153.471l-7.033-1.196a4.018 4.018 0 00-1.34 0l-7.033 1.196a.25.25 0 01-.153-.471l6.962-3.48a1.995 1.995 0 011.788 0zm.12-8.041l8.132 4.783a.5.5 0 01-.352.921l-8.01-1.602a3.98 3.98 0 00-1.568 0l-8.01 1.602a.5.5 0 01-.352-.922l8.132-4.782a2 2 0 012.028 0z"
      ></path>
    </svg>
  );
}

export default IconDoubleUp;
