import React from "react";
import { useSelector } from "react-redux";
import styles from "./TooltipText.module.scss";

const TooltipText = () => {
  const currentScene = useSelector((state) => state.appReducer.currentScene);
  console.log(currentScene);
  if (!currentScene.infoText) return null;
  return <div className={styles.text}>{currentScene.infoText}</div>;
};

export default TooltipText;
