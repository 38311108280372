import React from "react";
import IconTempLow from "assets/svg/IconTempLow";
import IconTempMedium from "assets/svg/IconTempMedium";
import styles from "./Sensor.module.css";
import { useSelector } from "react-redux";
import IconTempHigh from "assets/svg/IconTempHigh";

const getColorBasedOnTemp = (temperature) => {
  if (temperature < 26) {
    return "blue";
  } else if (temperature < 29) {
    return "black";
  } else {
    return "red";
  }
};

const getIconBasedOnTemp = (temperature) => {
  if (temperature < 26) {
    return IconTempLow;
  } else if (temperature < 29) {
    return IconTempMedium;
  } else {
    return IconTempHigh;
  }
};

const Sensor = () => {
  const currentScene = useSelector((state) => state.appReducer.currentScene);
  if (!currentScene?.temperature) {
    return null;
  }
  const temperature = currentScene.temperature;
  const color = getColorBasedOnTemp(temperature);
  const Icon = getIconBasedOnTemp(temperature);
  return (
    <div className={styles.sensorContainer}>
      <div className={styles.sensor}>
        <div className={`${styles[color]} ${styles["sensor-icon"]}`}>
          <Icon />
        </div>
        <div className={styles["sensor-reading"]}>{`${temperature}°C`}</div>
      </div>
    </div>
  );
};

export default Sensor;
