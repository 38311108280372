import { ButtonPrimary } from "components/button";
import IconSwitch from "components/iconSwitch";
import React from "react";
import { useSelector } from "react-redux";
import styles from "./Intro.module.scss";

const IntroScene = () => {
  const [show, setShow] = React.useState(false);
  const currentScene = useSelector((state) => state.appReducer.currentScene);

  React.useEffect(() => {
    if (currentScene?.place_intro) {
      setShow(true);
    }
  }, [currentScene]);

  React.useEffect(() => {
    if (show) {
      document.getElementById("root").classList.add("introIsOpen");
    } else {
      document.getElementById("root").classList.remove("introIsOpen");
    }
  }, [show]);

  if (!show) return null;
  const intro = currentScene?.place_intro;
  return (
    <div className={styles.overlay}>
      <div className={styles.wrapper}>
        <div className={styles.center}>
          {intro.icon && (
            <div className={styles.icon}>
              <IconSwitch icon={intro.icon} />
            </div>
          )}
          <h2 className={styles.title}>{intro.title}</h2>
          <div
            className={styles.text}
            dangerouslySetInnerHTML={{ __html: intro.text }}
          ></div>
        </div>
        <ButtonPrimary
          style={{ flex: "inherit" }}
          full
          onClick={() => setShow(false)}
        >
          Continuer
        </ButtonPrimary>
      </div>
    </div>
  );
};

export default IntroScene;
