import React from "react";

function IconPoiFinal() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12 3c2.455 0 4.444 2.056 4.444 4.592 0 1.487-1.146 4.035-3.438 7.644C18.056 15.376 22 16.56 22 18c0 1.534-4.477 2.778-10 2.778S2 19.534 2 18c0-1.44 3.944-2.624 8.996-2.764l-.074-.115c-2.244-3.548-3.366-6.057-3.366-7.529C7.556 5.056 9.546 3 12 3zm1.002 12.24l-.16.252a1 1 0 01-1.615.095l-.069-.095-.16-.251c-3.207.12-5.665.813-5.665 1.648 0 .92 2.985 1.667 6.667 1.667s6.667-.747 6.667-1.667c0-.835-2.458-1.527-5.665-1.648zM12 5.297c-1.227 0-2.222 1.028-2.222 2.296 0 1.268.995 2.296 2.222 2.296 1.227 0 2.222-1.028 2.222-2.296 0-1.268-.995-2.296-2.222-2.296z"
      ></path>
    </svg>
  );
}

export default IconPoiFinal;
