import IconArrowDown from "assets/svg/IconArrowDown";
import IconArrowLeft from "assets/svg/IconArrowLeft";
import IconArrowUp from "assets/svg/IconArrowUp";
import IconSoundOn from "assets/svg/IconSoundOn";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./Navbar.module.scss";
import NavbarDropdown from "./NavbarDropdown";

const Navbar = ({
  name,
  leftIcon,
  rightIcon,
  onLeftClick = () => {},
  goals,
  id,
  ...rest
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <div className={styles.navbar} {...rest}>
      <div className={styles.navbarContent}>
        {leftIcon && (
          <button
            onClick={onLeftClick}
            className={`${styles.navLeft} align-center`}
          >
            {leftIcon}
          </button>
        )}
        <div className={styles.navTitle} onClick={() => setIsOpen(!isOpen)}>
          <div className={styles.navTitleBtn}>
            <span>
              {name}
              {process.env.NODE_ENV === "development" ? `- ${id}` : null}
            </span>
            {isOpen ? <IconArrowUp /> : <IconArrowDown />}
          </div>

          {isOpen && <NavbarDropdown goals={goals} />}
        </div>
        {rightIcon && (
          <button className={`${styles.navLeft} align-center`}>
            {rightIcon}
          </button>
        )}
      </div>
    </div>
  );
};

// Navbar.defaultProps = {
//   rightIcon: <IconSoundOn />,
// };

export const MainNavbar = () => {
  const currentScene = useSelector((state) => state.appReducer.currentScene);
  const goals = useSelector((state) => state.appReducer.goals);
  return (
    <Navbar name={currentScene?.name} id={currentScene?.id} goals={goals} />
  );
};

export const DrawerNavbar = ({ name, onLeftClick }) => {
  const goals = useSelector((state) => state.appReducer.goals);
  return (
    <Navbar
      name={name}
      onLeftClick={onLeftClick}
      leftIcon={<IconArrowLeft />}
      goals={goals}
      style={{ position: "fixed", top: "0", left: "0", right: "0", zIndex: 10 }}
    />
  );
};

export default Navbar;
