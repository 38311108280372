import IconInterxion from "assets/logo/IconInterxion";
import IconMDB from "assets/logo/IconMdb";
import DalkiaLogoUrl from "assets/logo/dalkia-smart-building.png";
import AdemeLogoUrl from "assets/logo/ademe.png";
import RegionSudLogoUrl from "assets/logo/region-sud.png";
import IconFb from "assets/svg/IconFb";
import IconLeaf from "assets/svg/IconLeaf";
import IconLinkedin from "assets/svg/IconLinkedin";
import IconTwitter from "assets/svg/IconTwitter";
import IconWhatsapp from "assets/svg/IconWhatsapp";
import { ButtonOutline, ButtonPrimary } from "components/button";
import { motion } from "framer-motion";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import intro from "../intro/Intro.module.scss";
import styles from "./Closing.module.scss";

const socials = [
  {
    id: "facebook",
    url: "",
    icon: <IconFb />,
  },
  {
    id: "twitter",
    url: "",
    icon: <IconTwitter />,
  },
  {
    id: "linkedin",
    url: "",
    icon: <IconLinkedin />,
  },
  {
    id: "whatsapp",
    url: "",
    icon: <IconWhatsapp />,
  },
];

const steps = [
  {
    title: "18 400 MWh/an économisés",
    text: `<p>
    soit l’équivalent de la consommation moyenne de
    <strong>2 300 maisons de 70 m2 chauffées à l'électricité</strong>
    (environ 8 000 kWh/an).
  </p>`,
  },
  {
    title: "0 consommation d'eau",
    text: `<p>
    et <strong>aucune modification chimique</strong> 
de l'eau.
  </p>`,
  },
  {
    supTitle: "En projet",
    title: "Raccordement au réseau de chaleur urbain",
    text: `<p>
    jusqu'à <strong>500 000 m2 de logements chauffés</strong> grâce à la chaleur des data-centers.
  </p>`,
  },
  {
    title: "Partagez l’expérience",
    text: "Partagez l’expérience autour de vous.",
  },
];

const initialState = {
  currentStep: 0,
};

const CHANGE_STEP = "CHANGE_STEP";
const NEXT_STEP = "NEXT_STEP";
const PREV_STEP = "PREV_STEP";

function closingReducer(state, action) {
  switch (action.type) {
    case CHANGE_STEP:
      return {
        ...state,
        currentStep: action.payload,
      };
    case NEXT_STEP:
      return {
        ...state,
        currentStep: state.currentStep + 1,
      };
    case PREV_STEP:
      return {
        ...state,
        currentStep: state.currentStep - 1,
      };
    default:
      return state;
  }
}

const Closing = () => {
  const currentScene = useSelector((state) => state.appReducer.currentScene);
  const [state, dispatch] = React.useReducer(closingReducer, initialState);

  if (currentScene?.id !== "CLOSING") return null;

  const { currentStep } = state;
  const step = steps[currentStep];
  const variants = {
    inactive: {
      opacity: 0.5,
      scaleX: 0.666666667,
      borderRadius: "6px",
    },
    active: {
      opacity: 1,
      scaleX: 1,
      borderRadius: "4px",
    },
  };

  function handleReplay() {
    window.location.reload();
  }

  return (
    <div className={intro.overlay}>
      <div className={styles.wrapper}>
        <div className={styles.center}>
          {currentStep !== 3 && (
            <div className={styles.header}>
              <h2>Le River Cooling en&nbsp;synthèse</h2>
              <div className={styles.iconHead}>
                <IconLeaf />
              </div>
            </div>
          )}
          {currentStep !== 3 ? (
            <>
              {step.supTitle && (
                <div className={styles.supTitle}>{step.supTitle}</div>
              )}
              <h3 className={styles.title}>{step.title}</h3>
              <div
                className={styles.text}
                dangerouslySetInnerHTML={{ __html: step.text }}
              ></div>
              <div className={styles.infoText}>
                Pas de prélèvement d’eau et pas d’impact sur la faune et flore
              </div>
            </>
          ) : (
            <div className={styles.sharing}>
              <h3>{step.title}</h3>
              <p>{step.text}</p>
              <div className={styles.sharingGrid}>
                {socials.map((s) => (
                  <a href="#" key={s.id}>
                    {s.icon}
                  </a>
                ))}
              </div>
              <div className={styles.logos}>
                <a
                  target="_blank"
                  href="https://www.interxion.com"
                  className={styles.btnlogo}
                >
                  <IconInterxion />
                </a>
                <div>
                  <a
                    target="_blank"
                    href="https://mydigitalbuildings.com/?utm_source=smartport-demonstrateur&utm_medium=logo&utm_campaign=rivercoolingdemo_2020-11-27--smartport-demonstrateur-logo"
                    className={styles.btnlogo}
                  >
                    <IconMDB />
                  </a>
                  <a
                    target="_blank"
                    href="https://www.dalkiasmartbuilding.fr/"
                    className={styles.btnlogo}
                  >
                    <img src={DalkiaLogoUrl} height="16" width="94" />
                  </a>
                </div>
              </div>
              <div className={styles.partners}>
                <p>
                  Projet Rivercooling réalisé avec le soutien technique et financier de l’ADEME et
                  de la Région Sud
                </p>
                <div className={styles.partnersLogo}>
                  <img src={AdemeLogoUrl} height="48" width="48" />
                  <img src={RegionSudLogoUrl} height="32" width="132" />
                </div>
              </div>
            </div>
          )}
        </div>
        <div className={styles.bottom}>
          {currentStep !== 3 ? (
            <ButtonPrimary full onClick={() => dispatch({ type: NEXT_STEP })}>
              Continuer
            </ButtonPrimary>
          ) : (
            <ButtonOutline full onClick={handleReplay}>
              Rejouer l'expérience
            </ButtonOutline>
          )}
          <div className={styles.nav}>
            {steps.map((s, i) => {
              return (
                <motion.div
                  initial={false}
                  animate={i === currentStep ? "active" : "inactive"}
                  variants={variants}
                  key={`dot-${i}`}
                  className={`${styles.dot} ${
                    i === currentStep ? styles.dotActive : ""
                  }`}
                ></motion.div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Closing;
