import React from "react";

function IconLock() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12 3a6 6 0 016 6v2a1 1 0 011 1v8a1 1 0 01-1 1H6a1 1 0 01-1-1v-8a1 1 0 011-1h9V9a3 3 0 10-6 0v2H6V9a6 6 0 016-6z"
      ></path>
    </svg>
  );
}

export default IconLock;
