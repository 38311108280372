import React from "react";

function IconTempHigh() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill="currentColor" fillRule="evenodd">
        <path d="M11.8 1.005A4 4 0 008 5l-.001 10.002-.07.094A5 5 0 1017 18l-.007-.263a4.982 4.982 0 00-.921-2.64L16 15.004V5c0-2.21-1.79-4-4-4l-.2.005zM12 3a2 2 0 012 2l.001 10.733.25.284a3 3 0 11-4.501-.001l.25-.284V5a2 2 0 012-2z"></path>
        <path d="M12 7a1 1 0 011 1l.001 8.268a2 2 0 11-2.001 0V8a1 1 0 011-1z"></path>
      </g>
    </svg>
  );
}

export default IconTempHigh;
