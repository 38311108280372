import React from "react";

function IconPlay() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M18.86 12.814L7.582 20.87a1.001 1.001 0 01-1.581-.814V3.943a1 1 0 011.581-.814l11.28 8.057a1 1 0 010 1.628z"
      ></path>
    </svg>
  );
}

export default IconPlay;
