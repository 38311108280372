import CardExperience from "components/card/CardExperience";
import { EXPERIENCE_TYPE } from "marzipano/data";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useToggle } from "react-use";
import ColdCorridorExperience from "./coldCorridor";
import ColdUnit from "./coldUnit";
import PlaqueHeat from "./plaqueHeat";
import SettlingBassin from "./settlingBassin";
import ModalUnlock from "./unlock";
import ValveClosing from "./valveClosing";
import WaterPumping from "./waterPumping";

const ExperienceSwitcher = () => {
  const [showPreviewCard, setShowPreviewCard] = useToggle(true);
  const currentExperience = useSelector(
    (state) => state.appReducer.currentExperience
  );
  const experiences = useSelector((state) => state.appReducer.experiences);
  const dispatch = useDispatch();

  if (!currentExperience) return null;

  const experience = experiences.find((e) => {
    if (e.experience_id === currentExperience) return e;
  });

  if (
    showPreviewCard &&
    experience &&
    experience.display_experience_card !== "none"
  ) {
    return (
      <CardExperience
        handleClose={() => dispatch({ type: "END_EXPERIENCE" })}
        handleContinue={setShowPreviewCard}
        experience={experience}
      />
    );
  }

  switch (currentExperience) {
    case EXPERIENCE_TYPE.SAS_UNLOCK:
      return <ModalUnlock />;
    case EXPERIENCE_TYPE.ROOFTOP_VALVE_OPENING:
      return <ValveClosing />;
    case EXPERIENCE_TYPE.COLD_CORRIDOR:
      return <ColdCorridorExperience />;
    case EXPERIENCE_TYPE.PLATE_HEAT_EXCHANGER:
      return <PlaqueHeat />;
    case EXPERIENCE_TYPE.WATER_PUMPING:
      return <WaterPumping />;
    case EXPERIENCE_TYPE.COLD_UNIT_PRESENTATION:
      return <ColdUnit />;
    case EXPERIENCE_TYPE.SETTLING_BASSIN:
      return <SettlingBassin />;
    default:
      break;
  }
};

export default ExperienceSwitcher;
