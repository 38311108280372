import React from "react";
import Onboard from "./Onboard";
import styles from "./Splash.module.scss";
import WelcomeScreen from "./Welcome";

const Splash = ({ handleClose }) => {
  const [showOnboarding, setShowOnboarding] = React.useState(false);
  return (
    <div className={styles.splash}>
      {!showOnboarding ? (
        <WelcomeScreen handleNext={() => setShowOnboarding(true)} />
      ) : (
        <Onboard handleClose={handleClose} />
      )}
    </div>
  );
};

export default Splash;
