import React from "react";
import Marzipano from "marzipano";
import "./style.css";
import {
  detectPlatform,
  detectTouchDevice,
  hotspotInViewport,
  createSceneViewer,
  createHotspot,
  createToolTip,
} from "./utils";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

const MarzipanoReact = () => {
  const panoRef = React.useRef();
  const viewerRef = React.useRef();
  const settings = useSelector((state) => state.appReducer.settings);
  const currentScene = useSelector((state) => state.appReducer.currentScene);
  const goals = useSelector((state) => state.appReducer.goals);
  const dispatch = useDispatch();

  React.useEffect(() => {
    // Initialize viewer
    viewerRef.current = new Marzipano.Viewer(panoRef.current, {
      control: settings,
    });
    dispatch({ type: "INIT_FIRST_SCENE" });
    detectPlatform();
    detectTouchDevice();
  }, []);

  React.useEffect(() => {
    if (currentScene) {
      const sceneViewer = createSceneViewer({
        scene: currentScene,
        viewer: viewerRef.current,
      });
      // create main hotspot
      const hotspotData = currentScene.infoHotspots[0];
      const mainHotspot = createHotspot({
        hotspotData,
        type: "info",
        sceneViewer,
        props: {
          handleSwitchScene: () => {
            if (hotspotData.type === "experience_action") {
              const currentGoal = goals.find(
                (g) => g.id === currentScene?.goal_card?.goal_id
              );
              dispatch({
                type: "START_EXPERIENCE",
                currentGoal: currentGoal,
              });
            } else {
              dispatch({
                type: "GO_TO_SCENE_BY_ID",
                sceneId: hotspotData.target,
              });
            }
          },
        },
      });
      // Create link hotspots.
      currentScene.linkHotspots.forEach(function (hotspotData) {
        createHotspot({
          hotspotData,
          type: "link",
          sceneViewer,
          props: {
            scene: sceneViewer,
            handleSwitchScene: () => {
              dispatch({
                type: "GO_TO_SCENE_BY_ID",
                sceneId: hotspotData.target,
              });
            },
          },
        });
      });

      currentScene.tooltips?.forEach(function (tooltip) {
        createToolTip({
          tooltip,
          sceneViewer,
        });
      });

      sceneViewer._view.addEventListener("change", function () {
        const coordHotspot = sceneViewer._view.coordinatesToScreen(
          mainHotspot._coords
        );
        if (coordHotspot) {
          const position = hotspotInViewport(coordHotspot);
          dispatch({
            type: "SET_EDGE_POSITION",
            edge: {
              position,
              recenter: () => {
                sceneViewer._view.setParameters(hotspotData);
              },
            },
          });
        }
      });

      sceneViewer._view.setParameters(currentScene.initialViewParameters);
      sceneViewer.switchTo();

      return function cleanup() {
        sceneViewer._view.removeEventListener("change");
      };
    }
  }, [currentScene]);

  return <div id="pano" ref={panoRef} />;
};

export default MarzipanoReact;
