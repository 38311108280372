import React from "react";

function IconLeaf() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="96"
      height="96"
      viewBox="0 0 96 96"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M65.987 64.958C78.337 52.612 81.234 12 81.234 12s-8.385 4.371-21.78 5.52c-12.63 1.085-24.328 2.867-31.182 9.721-10.579 10.581-13.226 25.64-7.205 36.283 6.628-10.4 33.88-25.239 33.88-25.239C35.387 52.274 25.42 63.121 12 82.238L18.58 84s4.9-8.847 9.159-13.122c10.688 7.584 26.993 5.34 38.248-5.92z"
      ></path>
    </svg>
  );
}

export default IconLeaf;
