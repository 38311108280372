import React from "react";

function IconCheck() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M16.336 4.89a2 2 0 013.427 2.054l-.1.166-7.999 12a2 2 0 01-2.933.436l-.145-.132-4-4a2 2 0 012.676-2.965l.152.137 2.274 2.274 6.648-9.97z"
      ></path>
    </svg>
  );
}

export default IconCheck;
