import React from "react";

function IconPoi(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M18.906 11.084L7.074 5.907a1.001 1.001 0 00-1.347 1.237l1.535 4.535c.07.208.07.434 0 .642l-1.536 4.535a1 1 0 001.348 1.237l11.832-5.177a.999.999 0 000-1.832z"
      ></path>
    </svg>
  );
}

export default IconPoi;
