import React from "react";
import IconInterxion from "assets/logo/IconInterxion";
import IconMDB from "assets/logo/IconMdb";
import DalkiaLogoUrl from "assets/logo/dalkia-smart-building.png";
import { ButtonPrimary } from "components/button";
import { useEffect } from "react";
import styles from "./Splash.module.scss";

import { useTranslation } from "react-i18next";

const Welcome = ({ handleNext }) => {
  const { t } = useTranslation(["components"]);

  return (
    <div className={styles.center}>
      <div className={styles.title}>
        <strong>{t("welcome.title")} </strong> <br />
        {t("welcome.subtitle")}
      </div>

      <div className={styles.text}>
        <p>{t("welcome.firstParagraph")}</p>
        <p>{t("welcome.secondParagraph")}</p>
      </div>
      <ButtonPrimary full onboard onClick={handleNext}>
        {t("common:start")}
      </ButtonPrimary>
      <div className={styles.logos}>
        <a
          target="_blank"
          href="https://www.interxion.com"
          className={styles.btnlogo}
        >
          <IconInterxion />
        </a>
        <a
          target="_blank"
          href="https://mydigitalbuildings.com/?utm_source=smartport-demonstrateur&utm_medium=logo&utm_campaign=rivercoolingdemo_2020-11-27--smartport-demonstrateur-logo"
          className={styles.btnlogo}
        >
          <IconMDB />
        </a>
        <a
          target="_blank"
          href="https://www.dalkiasmartbuilding.fr/"
          className={styles.btnlogo}
        >
          <img src={DalkiaLogoUrl} height="32" width="187" />
        </a>
      </div>
    </div>
  );
};

export default Welcome;
