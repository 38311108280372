import IconFinger from "assets/svg/IconFinger";
import React from "react";
import ProgressCircle from "./ProgressCircle";
import styles from "./FingerPrint.module.scss";

const FingerPrint = ({ handleSetActive, active }) => {
  return (
    <ProgressCircle active={active}>
      <div className={styles.hotspot} onClick={handleSetActive}>
        <IconFinger />
      </div>
    </ProgressCircle>
  );
};

export default FingerPrint;
